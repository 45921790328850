import { BehaviorSubject, Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { Asset, AssetOrder } from '../models/asset';
import { api_asset_box, asset_box } from '../models/asset-box';
import { AuthService } from './auth.service';
import { HttpService } from './http.service';

/**
 * Observableの処理を代理で行うコンポーネント、検査で変更される値はここで管理される
 */
@Injectable({
  providedIn: 'root',
})
export class ProcessService {
  private step: BehaviorSubject<string>;
  private machineID: BehaviorSubject<string>;
  private compactPC: BehaviorSubject<string>;
  private jobID: BehaviorSubject<string>;
  private operationPhase: BehaviorSubject<string>;
  private assets: BehaviorSubject<Asset[]> = new BehaviorSubject([]);
  private apiAssets: BehaviorSubject<api_asset_box[]> = new BehaviorSubject([]);
  private isExistConfig: BehaviorSubject<boolean>;
  private ipAddress: BehaviorSubject<string>;
  private subnetMask: BehaviorSubject<string>;
  private portNumber: BehaviorSubject<string>;
  private isCompleteJsonPut: BehaviorSubject<boolean>;
  private testProcess: BehaviorSubject<string>;

  constructor(private http: HttpService, private auth: AuthService) {
    const nowStep = localStorage.getItem('Step');
    const machineID = localStorage.getItem('MachineID');
    const compactPC = localStorage.getItem('CompactPC');
    const jobID = localStorage.getItem('JobID');
    const operationPhase = localStorage.getItem('OperationPhase');
    const isExistConfig = localStorage.getItem('IsExistConfig');
    const ipAddress = localStorage.getItem('IPAddress');
    const subnetMask = localStorage.getItem('SubnetMask');
    const portNumber = localStorage.getItem('PortNumber');
    const isCompleteJsonPut = localStorage.getItem('IsCompleteJsonPut');
    const testProcess = localStorage.getItem('TestProcess');

    this.step = new BehaviorSubject(nowStep !== null ? nowStep : undefined);
    this.machineID = new BehaviorSubject(machineID !== null ? machineID : '-');
    this.compactPC = new BehaviorSubject(compactPC !== null ? compactPC : '-');
    this.jobID = new BehaviorSubject(jobID);
    this.isExistConfig = new BehaviorSubject(Boolean(isExistConfig));
    this.operationPhase = new BehaviorSubject(operationPhase);
    this.ipAddress = new BehaviorSubject(ipAddress);
    this.subnetMask = new BehaviorSubject(subnetMask);
    this.portNumber = new BehaviorSubject(portNumber);
    this.isCompleteJsonPut = new BehaviorSubject(Boolean(isCompleteJsonPut));
    this.testProcess = new BehaviorSubject(testProcess);
  }

  resetInspectProcess(): void {
    localStorage.removeItem('Step');
    localStorage.removeItem('MachineID');
    localStorage.removeItem('CompactPC');
    localStorage.removeItem('JobID');
    localStorage.removeItem('OperationPhase');
    localStorage.removeItem('IsExistConfig');
    localStorage.removeItem('IPAddress');
    localStorage.removeItem('SubnetMask');
    localStorage.removeItem('PortNumber');
    localStorage.removeItem('IsCompleteJsonPut');
    localStorage.removeItem('TestProcess');
    this.step = new BehaviorSubject(undefined);
    this.machineID = new BehaviorSubject('-');
    this.compactPC = new BehaviorSubject('-');
    this.jobID = new BehaviorSubject(null);
    this.operationPhase = new BehaviorSubject('Init');
    this.isExistConfig = new BehaviorSubject(false);
    this.ipAddress = new BehaviorSubject(null);
    this.subnetMask = new BehaviorSubject('-');
    this.portNumber = new BehaviorSubject('-');
    this.isCompleteJsonPut = new BehaviorSubject(false);
    this.testProcess = new BehaviorSubject('-');

    console.log('resetInspectProcess() Completed.');
  }

  stepNext(step: string): void {
    this.step.next(step);
    localStorage.setItem('Step', step);
  }

  machineIDNext(machineID: string): void {
    this.machineID.next(machineID);
    localStorage.setItem('MachineID', machineID);
  }

  compactPCNext(compactPC: string): void {
    const result = this.toCosima(compactPC);
    this.compactPC.next(result);
    localStorage.setItem('CompactPC', result);
  }

  jobIDNext(jobID: string): void {
    this.jobID.next(jobID);
    localStorage.setItem('JobID', jobID);
  }

  operationPhaseNext(operationPhase: string): void {
    this.operationPhase.next(operationPhase);
    localStorage.setItem('OperationPhase', operationPhase);
  }

  assetsNext(assets: Asset[]): void {
    const assetList: Asset[] = [];
    for (const asset of assets) {
      assetList.push(new Asset(asset));
      console.log(`asset.AssetName : ${asset.AssetName}`);
    }
    assetList.sort((a, b) => {
      const subA = a.AssetName.substring(0, 2);
      const subB = b.AssetName.substring(0, 2);
      return AssetOrder[subA] - AssetOrder[subB];
    });
    this.assets.next(assetList);
  }

  apiAssetsNext(apiAssets: api_asset_box[]): void {
    const assetList: Asset[] = [];
    for (const asset of apiAssets) {
      assetList.push(new Asset(asset));
      console.log(`asset.AssetName : ${asset.AssetName}`);
    }
    assetList.sort((a, b) => {
      const subA = a.AssetName.substring(0, 2);
      const subB = b.AssetName.substring(0, 2);
      return AssetOrder[subA] - AssetOrder[subB];
    });
    this.apiAssets.next(assetList);
  }

  isExistConfigNext(isExistConfig: boolean): void {
    this.isExistConfig.next(isExistConfig);
    localStorage.setItem('IsExistConfig', isExistConfig.toString());
  }

  ipAddressNext(ipAddress: string): void {
    this.ipAddress.next(ipAddress);
    localStorage.setItem('IPAddress', ipAddress);
  }

  subnetMaskNext(subnetMask: string): void {
    this.subnetMask.next(subnetMask);
    localStorage.setItem('SubnetMask', subnetMask);
  }

  portNumberNext(portNumber: string): void {
    this.portNumber.next(portNumber);
    localStorage.setItem('PortNumber', portNumber);
  }

  isCompleteJsonPutNext(isCompleteJsonPut: boolean): void {
    this.isCompleteJsonPut.next(isCompleteJsonPut);
    localStorage.setItem('IsCompleteJsonPut', isCompleteJsonPut.toString());
  }

  testProcessNext(testProcess: string): void {
    this.testProcess.next(testProcess);
    localStorage.setItem('TestProcess', testProcess);
  }

  observeStep(): Observable<string> {
    return this.step.asObservable();
  }

  observeMachineID(): Observable<string> {
    return this.machineID.asObservable();
  }

  observeCompactPC(): Observable<string> {
    return this.compactPC.asObservable();
  }

  observeJobID(): Observable<string> {
    return this.jobID.asObservable();
  }
  observeOperationPhase(): Observable<string> {
    return this.operationPhase.asObservable();
  }

  observeAssets(): Observable<Asset[]> {
    return this.assets.asObservable();
  }

  observeApiAssets(): Observable<api_asset_box[]> {
    return this.apiAssets.asObservable();
  }

  observeIsExistConfig(): Observable<boolean> {
    return this.isExistConfig.asObservable();
  }

  observeIpAddress(): Observable<string> {
    return this.ipAddress.asObservable();
  }

  observeSubnetMask(): Observable<string> {
    return this.subnetMask.asObservable();
  }
  observePortNumber(): Observable<string> {
    return this.portNumber.asObservable();
  }
  
  observeIsCompleteJsonPut(): Observable<boolean> {
    return this.isCompleteJsonPut.asObservable();
  }

  observeTestProcess():Observable<string> {
    return this.testProcess.asObservable();
  }
  
  private toCosima(compactPC: string): string {
    const re = /Komainu*/;
    if (re.test(compactPC)) {
      const result = compactPC.replace(re, 'Cosima');
      return result;
    }
    return compactPC;
  }
}
