// ---------------------------------------------------------------------
// <copyright file="anomaly-result.component.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Subscription } from 'rxjs';
import { DialogOverviewComponent } from 'src/app/component-parts/others/dialog-overview/dialog-overview.component';
import { Asset } from 'src/app/models/asset';
import { AuthService } from 'src/app/services/auth.service';
import { ButtonService } from 'src/app/services/button.service';
import { HttpService } from 'src/app/services/http.service';
import { ProcessService } from 'src/app/services/process.service';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

export const DefaultImage = encodeURI('/assets/komainu_icon/写真アイコン9.png');

/**
 * 現在検査している機械の検査結果を表示するコンポーネント
 */
@Component({
  selector: 'app-anomaly-result',
  templateUrl: './anomaly-result.component.html',
  styleUrls: ['./anomaly-result.component.css'],
})
export class AnomalyResultComponent implements OnInit, OnDestroy {
  public comment = 'の検査を実施中・・・';
  public step: string;
  public pre_assets: Asset[];
  public init_assets: Asset[] = Array();
  public assets: Asset[];
  public requestAssetNameList: string[] = [];
  public machineID: string;
  public isExistConfig: boolean;
  private timer;

  public reactiveForm: FormGroup;
  public username: string;
  public send_comment: string;
  public future_comment: string;
  public testProcess: string;
  public waitingMessage: string;

  private subscribeStep: Subscription;
  private subscribeButton: Subscription;
  private subscribeAssets: Subscription;
  private subscribeApiAssets: Subscription;
  private subscribeImage: Subscription;
  private subscribeIsExistConfig: Subscription;

  public Step5_box = {
    first: 'Step5_1',
    second: 'Step5_2',
    third: 'Step5_3',
    forth: 'Step5_4',
  };

  constructor(
    private processService: ProcessService,
    private button: ButtonService,
    private http: HttpService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private auth: AuthService
  ) {
    this.username = this.auth.username;
    this.reactiveForm = this.formBuilder.group({
      message: [''],
    });
  }

  ngOnInit(): void {
    this.waitingMessage = '';
    this.machineID = localStorage.getItem('MachineID');
    this.testProcess = localStorage.getItem('TestProcess');

    // 全Assetsを読み込む
    this.subscribeAssets = this.processService
      .observeAssets()
      .subscribe((assets) => {
        this.pre_assets = assets;
        console.log('初期のassetはこちら');
        console.log(this.assets);
      });

    // 全Assetsを読み込む
    this.subscribeApiAssets = this.processService
      .observeApiAssets()
      .subscribe((api_asset_box) => {
        for (var i = 0; i < api_asset_box.length; i++) {
          for (var j = 0; j < this.pre_assets.length; j++) {
            if (api_asset_box[i].AssetName === this.pre_assets[j].AssetName) {
              this.init_assets.push(this.pre_assets[j]);
            }
          }
        }
        this.assets = this.init_assets;
        console.log('init_assetはこちら');
        console.log(this.init_assets);
      });

    this.subscribeStep = this.processService.observeStep().subscribe((step) => {
      this.step = step;
      if (step === 'Step5_1') {
        console.log('Step5_1 Action Started!');
        this.button.disabledNext(false);
        this.comment = 'の検査を実施中・・・';
        this.processService.assetsNext(this.assets);
      } else if (step === 'Step5_2') {
        console.log('Step5_2 Action Started!');
        this.button.disabledNext(false);

        // 繰り返し検査用に、assetsの中身を空にする
        this.assets = this.initAssets(this.assets);
        this.requestAssetNameList = [];

        // bodyに組み込む用の初期requestAssetNameListを作る
        this.assets.forEach((asset) => {
          this.requestAssetNameList.push(asset.AssetName);
        });

        console.log('requestAssetNameList Init');
        console.log(this.requestAssetNameList);

        // 診断画像が全て揃うまで繰り返しデータを見に行く
        this.timer = setInterval(() => {
          // webapi用のbodyを準備
          let body = {
            machineID: this.machineID,
            assets: this.requestAssetNameList,
            testProcess: this.testProcess,
          };

          ////////// WEB API //////////
          this.subscribeImage = this.http
            .httpPostRequest<any>(`/anomalypreproimageget/`, body)
            .subscribe((response) => {
              console.log(response);
              this.assets.forEach((asset) => {
                // ローカルでスコアがないandレスポンスのスコアがある場合にデータ取得
                if (
                  asset.Score === null &&
                  response[asset.AssetName].AnomalyMax != null
                ) {
                  asset.PreproSrc =
                    response[asset.AssetName].PreproPresignedUrl;
                  asset.ResultSrc =
                    response[asset.AssetName].AnomalyPresignedUrl;
                  asset.Score = response[asset.AssetName].AnomalyMax;
                  asset.TimeStamp = response[asset.AssetName].TimeStamp;
                  console.log('Webapi set asset Completed.');
                  console.log(asset);
                }
              });

              // 全ての軸が揃ったら、ステップを進める
              if (this.isGetScore(this.assets)) {
                this.processService.stepNext('Step5_3');
              }
            });
          /////////////////////////////

          // 取得できていない軸のリストを作成する
          let next_requestAssetNameList: string[] = [];
          this.assets.forEach((asset) => {
            if (asset.Score === null) {
              next_requestAssetNameList.push(asset.AssetName);
            }
          });

          // デバッグ用
          this.requestAssetNameList = next_requestAssetNameList;
          console.log('Renewing assetNameList... ');
          console.log(this.requestAssetNameList);
        }, 3000);
      } else if (step === 'Step5_3') {
        console.log('Step5_3 Action Started!');
        // タイマーを止める
        clearInterval(this.timer);
        this.button.disabledNext(false);
        this.comment = 'の検査が完了しました。';
      } else if (step === 'Step5_4') {
        // UnderButtonCompomnentでS3バケットのsave,deleteAPIを叩く

        // isExistConfigを読み込む
        this.subscribeIsExistConfig = this.processService
          .observeIsExistConfig()
          .subscribe((isExistConfig) => {
            this.isExistConfig = isExistConfig;
            console.log('isExistConfig');
            console.log(this.isExistConfig);
          });

        // isExistConfigの値で、commentを修正
        if (this.isExistConfig) {
          // Trueの場合
          const bold1 = '記録';
          const bold2 = '初期化は不要';
          this.comment = `の検査を${bold1.bold()}しました。\nNCのネットワーク設定の${bold2.bold()}です。\n配電盤のLANポートを元の状態に戻しましょう。`;
        } else {
          // Falseの場合
          const bold1 = '記録';
          const bold2 = '設定を初期化';
          this.comment = `の検査を${bold1.bold()}しました。\nNCのIPアドレス、サブネットマスク、ポート番号の${bold2.bold()}し、\n配電盤のLANポートを元の状態に戻しましょう。`;
        }

        this.button.disabledNext(false);
      }
      // this.changeDetect.detectChanges();  // 変更通知
    });
    this.subscribeButton = this.button
      .observeClickEvent()
      .subscribe((click) => {
        console.log(click, this.step);
        if (click && this.step === 'Step5_1') {
        } else if (click && this.step === 'Step5_2') {
        } else if (click && this.step === 'Step5_3') {
        } else if (click && this.step === 'Step5_4') {
        } else {
        }
      });
  }

  ngOnDestroy(): void {
    this.subscribeStep.unsubscribe();
    this.subscribeButton.unsubscribe();
    this.subscribeAssets.unsubscribe();
    this.subscribeApiAssets.unsubscribe();
    this.subscribeImage.unsubscribe();
    if (this.step === 'Step5_4') {
      this.subscribeIsExistConfig.unsubscribe();
    }
  }

  isGetScore(assets: Asset[]): boolean {
    for (const asset of assets) {
      console.log('isGetScore...判定');
      console.log(asset.AssetName);
      console.log(asset.Score);
      if (asset.Score === null) {
        return false;
      }
    }
    return true;
  }

  isAlartImage(score: number): boolean {
    if (score === null || score < 0) {
      return false;
    }
    return true;
  }

  alartText(assets: Asset[]): string {
    for (const asset of assets) {
      if (asset.Score >= 0) {
        return '異常'.fontcolor('red');
      }
    }
    return '正常';
  }

  initAssets(assets: Asset[]): Asset[] {
    for (var i = 0; i < assets.length; i++) {
      assets[i].PreproSrc = DefaultImage;
      assets[i].ResultSrc = DefaultImage;
      assets[i].Score = null;
      assets[i].TimeStamp = null;
    }
    console.log('assetsのImg初期化、Score・TimeStampの初期化完了');
    return assets;
  }

  sendComment(): void {
    // Formから送信するメッセージを取得
    this.waitingMessage = '';
    this.send_comment = this.reactiveForm.controls['message'].value;

    // WEB API POST用のbodyを作成する
    let body = {
      machineID: this.machineID,
      user: this.username,
      comment: this.send_comment,
      testProcess: this.testProcess,
    };

    this.waitingMessage = 'コメントを送信しています...';
    ////////// WEB API //////////
    this.http.httpPostRequest<any>('/commentput', body).subscribe(
      (response) => {
        console.log(response);
        console.log(`WebAPI /commentputapi Completed.}`);

        console.log(this.send_comment);
        this.waitingMessage = 'コメントを送信しました。';
        // コメントの初期化
        this.reactiveForm.controls['message'].setValue('');
      },
      () => {}
    );
    /////////////////////////////
  }

  openDialog(assetKey: string, PreproSrc: string, ResultSrc): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      PreproSrc: PreproSrc,
      ResultSrc: ResultSrc,
      AssetKey: assetKey,
    };
    // dialogConfig.height = '80vh';
    // dialogConfig.width = '500px';
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(DialogOverviewComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }
}
