// ---------------------------------------------------------------------
// <copyright file="machine-info.component.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { ApprovalWindowComponent } from 'src/app/component-parts/others/approval-window/approval-window.component';
import { AssetOrder } from 'src/app/models/asset';
import { AssetScore } from 'src/app/models/asset-score';
import {
  AllTestProcess,
  Machine,
  TestProcessHolder,
} from 'src/app/models/machinelist';
import { AuthService } from 'src/app/services/auth.service';
import { HttpService } from 'src/app/services/http.service';

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

/**
 * 機械の診断結果の詳細を表示するコンポーネント
 */
@Component({
  selector: 'app-machine-info',
  templateUrl: './machine-info.component.html',
  styleUrls: ['./machine-info.component.css'],
})
export class MachineInfoComponent implements OnInit, OnDestroy {
  public assets: AssetScore = {};
  public assetList: string[];
  public machine: Machine;
  public selectedProcess: string;
  public processList: string[] = new Array();
  public date: string;
  public username: string;
  public machineID: string;
  public selectedAsset: string;
  public anomalyImage = {};
  public preproImage = {};
  public imgURL: string;
  public allTestProcess = AllTestProcess;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpService,
    private auth: AuthService,
    private changeDetect: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.username = this.auth.username;
    this.machineID = this.route.snapshot.paramMap.get('id');
    ////////// WEB API //////////
    this.http
      .httpGetRequest<any>(`/resultmachinestatusget/${this.machineID}`)
      .subscribe((response) => {
        console.log(response);
        let temp_response = JSON.parse(JSON.stringify(response));
        this.setMachineStatus(temp_response);
      });
    /////////////////////////////;
  }

  ngOnDestroy(): void {}

  setMachineStatus(response: any): void {
    let machineID = response.MachineID;
    let testProcesses = {};
    let last_phase: string;
    let latestLastUpdate;
    for (const [phase, values] of Object.entries(response)) {
      // Responseの中に検査工程があるか判定する
      if (this.allTestProcess.includes(phase)) {
        let temp = new TestProcessHolder(
          values['Operator'],
          values['Approver'],
          values['Approval'],
          values['LastUpdate'],
          values['Assets']
        );
        testProcesses[phase] = temp;
        last_phase = phase;
        latestLastUpdate = temp.LastUpdate;
        this.processList.push(phase);
      }
    }
    console.log('testProcess');
    console.log(testProcesses);
    this.machine = new Machine(machineID, testProcesses, 0, latestLastUpdate);
    console.log('this.machine');
    console.log(this.machine);
    this.setTestProcess(last_phase, this.machine.TestProcessList[last_phase]);
  }

  // 検査項目が変化した場合の処理
  setTestProcess(
    testProcessName: string,
    testProcessHolder: TestProcessHolder
  ): void {
    this.selectedProcess = testProcessName;
    this.assets = new AssetScore(testProcessHolder.Assets);
    this.date = testProcessHolder.LastUpdate;
    this.assetList = Object.keys(this.assets);
    this.assetList.sort((a, b) => {
      const subA = a.substring(0, 2);
      const subB = b.substring(0, 2);
      return AssetOrder[subA] - AssetOrder[subB];
    });
    console.log(this.assetList);
    for (const assetKey of Object.keys(this.assets)) {
      this.anomalyImage[assetKey] = '';
    }
    this.route.queryParams.subscribe((params) => {
      this.setAsset(params.asset);
    });

    this.getAnomalyImage('SP1');
  }

  // 選択された工程の検査担当者を返す
  getOperator(): string {
    return this.machine.TestProcessList[this.selectedProcess].Operator;
  }

  // 画面中央に表示する画像を取得するWebAPIを叩く
  getAnomalyImage(selectedAsset: string): void {
    if (this.anomalyImage[selectedAsset] === '') {
      ////////// WEB API //////////
      this.http
        .httpGetRequest<any>(
          `/resultsavedimagesget/${this.machineID}?asset=${this.selectedAsset}&testProcess=${this.selectedProcess}`
        )
        .subscribe((response) => {
          console.log(response);
          this.anomalyImage[selectedAsset] = response.AnomalyPresignedUrl;
          this.preproImage[selectedAsset] = response.PreproPresignedUrl;
        });
      /////////////////////////////
      this.changeDetect.detectChanges(); // 変更通知
    }
  }

  // 選択するかどうかでボタンのCSSを変更する
  selectedCSS(testProcessName: string): string {
    if (testProcessName === this.selectedProcess) {
      return 'selected';
    }
    return 'not-selected';
  }

  // AssetからWebAPIを呼び出す
  setAsset(assetKey: string): void {
    this.selectedAsset = assetKey;
    this.getAnomalyImage(this.selectedAsset);
  }
  // AssetからWebAPIを呼び出す(左矢印)
  previousAsset(nowAsset: string): void {
    const index = this.assetList?.findIndex((asset) => asset === nowAsset);
    if (index !== 0) {
      this.setAsset(this.assetList[index - 1]);
    }
  }

  // AssetからWebAPIを呼び出す(右矢印)
  nextAsset(nowAsset: string): void {
    const index = this.assetList?.findIndex((asset) => asset === nowAsset);
    if (index !== this.assetList?.length - 1) {
      this.setAsset(this.assetList[index + 1]);
    }
  }

  // 左矢印の表示処理
  stylePrePageing(nowAsset: string): string {
    const index = this.assetList?.findIndex((asset) => asset === nowAsset);
    if (index === 0) {
      return 'hidden';
    }
    return '';
  }

  // 右矢印の表示処理
  styleNextPageing(nowAsset: string): string {
    const index = this.assetList?.findIndex((asset) => asset === nowAsset);
    if (index === this.assetList?.length - 1) {
      return 'hidden';
    }
    return '';
  }

  // 検査結果一覧画面へ戻る
  clickList(event: string): void {
    this.router.navigate(['/list']);
  }

  // チェックボックスをオンにする際の処理
  checkApproval(testProcess: string): void {
    let message = '承認を行いますか？';
    let isCheck = true;
    this.openDialog(isCheck, message, testProcess);
  }

  // チェックボックスをオフにする際の処理
  unCheckApproval(testProcess: string): void {
    let message = '承認を取り消しますか？';
    let isCheck = false;
    this.openDialog(isCheck, message, testProcess);
  }

  // 承認確認ボタンをダイアログで表示し、選択結果をWebAPIに送信する
  openDialog(isCheck: boolean, message: string, testProcess: string): void {
    // ダイアログで表示するメッセージを格納する
    const dialogRef = this.dialog.open(ApprovalWindowComponent, {
      height: '200px',
      width: '400px',
      data: { message: message },
    });

    dialogRef.afterClosed().subscribe((result) => {
      let cancelFlug = false;

      // stringからbooleanへの変換を行い、Trueの場合のみWebAPIを叩く
      if (Boolean(result)) {
        let approvalFlug: boolean;

        // isCheckの中身に応じて、approvalFlugの真偽値を変更する
        if (isCheck) {
          approvalFlug = Boolean(result);
        } else {
          approvalFlug = !Boolean(result);
          cancelFlug = true;
        }

        let body = {
          machineID: this.machineID,
          testProcess: testProcess,
          approval: approvalFlug,
        };

        ////////// WEB API //////////
        this.http.httpPostRequest<any>('/inspectapprove', body).subscribe(
          (response) => {
            console.log(response);
            console.log(`WebAPI /inspectapprove Completed.}`);

            // 承認取り消しのコメントを承認する
            if (cancelFlug) {
              this.sendComment(testProcess);
              // 画面を再読み込みする
              this.ngOnInit();
            } else {
              // 画面を再読み込みする

              this.ngOnInit();
            }
          },
          () => {}
        );
        /////////////////////////////
      }
    });
  }

  sendComment(testProcess: string): void {
    // WEB API POST用のbodyを作成する
    let body = {
      machineID: this.machineID,
      user: 'System_',
      comment: `${this.username}さんが、承認を取り消しました。`,
      testProcess: testProcess,
    };

    ////////// WEB API //////////
    this.http.httpPostRequest<any>('/commentput', body).subscribe(
      (response) => {
        console.log(response);
        console.log(`WebAPI /commentputapi Completed.}`);
        return true;
      },
      () => {
        return false;
      }
    );
    /////////////////////////////
  }
}
