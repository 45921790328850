// ---------------------------------------------------------------------
// <copyright file="preparetion.component.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Explanations, ImagePath } from 'src/app/models/nc-netconfig-photo';
import { ButtonService } from 'src/app/services/button.service';

import { Component, OnDestroy, OnInit } from '@angular/core';

/**
 * 最初に必要な道具を確認してもらう画像を表示するコンポーネント
 */
@Component({
  selector: 'app-preparetion',
  templateUrl: './preparetion.component.html',
  styleUrls: ['./preparetion.component.css'],
})
export class PreparetionComponent implements OnInit, OnDestroy {
  public nowPage: string;
  public imagePath: typeof ImagePath = ImagePath;
  public explanations: typeof Explanations = Explanations;

  constructor(private buttton: ButtonService) {}

  ngOnInit(): void {
    this.buttton.disabledNext(false);
    this.nowPage = 'Pre';
  }

  ngOnDestroy(): void {}
}
