// ---------------------------------------------------------------------
// <copyright file="qrcode-input.component.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Subscription } from 'rxjs';
import { QrTestprocessPipe } from 'src/app/pipes/qr-testprocess.pipe';
import { ButtonService } from 'src/app/services/button.service';
import { ProcessService } from 'src/app/services/process.service';

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qrcode-input',
  templateUrl: './qrcode-input.component.html',
  styleUrls: ['./qrcode-input.component.css'],
})
export class QrcodeInputComponent implements OnInit {
  public Step2_box = {
    first: 'Step2_1',
    second: 'Step2_2',
    third: 'Step2_3',
  };
  public step: string;
  public compactPC: string;
  public testProcess: string;
  public machineID: string;
  private subscribeButton: Subscription;
  private subscribeStep: Subscription;
  private testprocessPipe = new QrTestprocessPipe();

  constructor(
    private processService: ProcessService,
    private button: ButtonService
  ) {}

  ngOnInit(): void {
    this.button.disabledNext(true);

    this.subscribeStep = this.processService.observeStep().subscribe((step) => {
      this.step = step;
    });

    this.subscribeButton = this.button
      .observeClickEvent()
      .subscribe((click) => {
        console.log(click, this.step);
        if (click && this.step === 'Step2_1') {
          console.log(this.compactPC);
          this.processService.compactPCNext(this.compactPC);
          this.button.disabledNext(true);
        } else if (click && this.step === 'Step2_2') {
          console.log(this.testProcess);
          this.processService.testProcessNext(this.testProcess);
          this.button.disabledNext(true);
        } else if (click && this.step === 'Step2_3') {
          console.log(this.machineID);
          this.processService.machineIDNext(this.machineID);
          this.button.disabledNext(true);
        }
      });
  }

  ngOnDestroy(): void {
    this.subscribeButton.unsubscribe();
    this.subscribeStep.unsubscribe();
  }

  testProcessQrcodeAccept(event: string): void {
    this.testProcess = this.testprocessPipe.transform(event);
    this.button.disabledNext(false);
  }

  compactPCQrcodeAccept(event: string): void {
    this.compactPC = event;
    this.button.disabledNext(false);
  }
  machineIDQrcodeAccept(event: string): void {
    this.machineID = event;
    this.button.disabledNext(false);
  }
}
