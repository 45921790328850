import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-display-loading',
  templateUrl: './display-loading.component.html',
  styleUrls: ['./display-loading.component.css']
})

export class DisplayLoadingComponent {
  @Input() loading: boolean = false;
}