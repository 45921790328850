// ---------------------------------------------------------------------
// <copyright file="asset-score.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { AssetStatus } from './asset-status';

export class AssetScore {
  [index: string]: {
    Score: number;
    State: AssetStatus;
    Model: string;
    Datatype: string;
  };

  constructor(assets?: AssetScore) {
    for (const [assetKey, assetValue] of Object.entries(assets)) {
      this[assetKey] = {
        Score: assetValue.Score,
        State: undefined,
        Model: assetValue.Model,
        Datatype: assetValue.Datatype,
      };
      if (assetValue.Score < 0) {
        this[assetKey].State = AssetStatus.normal;
      } else if (assetValue.Score < 1.0) {
        this[assetKey].State = AssetStatus.warning;
      } else {
        this[assetKey].State = AssetStatus.critical;
      }
    }
  }
}
