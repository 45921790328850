// ---------------------------------------------------------------------
// <copyright file="app-info.component.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { updatedate, updatetext, version } from 'package.json';

import { Component, OnDestroy, OnInit } from '@angular/core';

/**
 * アプリのバージョンを表示するコンポーネント
 * - HomeコンポーネントとLoginコンポーネントで呼び出される
 * */
@Component({
  selector: 'app-app-info',
  templateUrl: './app-info.component.html',
  styleUrls: ['./app-info.component.css'],
})
export class AppInfoComponent implements OnInit, OnDestroy {
  public AppVer: string;
  public updateDate: string;
  public updateText: string;

  constructor() {
    this.AppVer = version;
    this.updateDate = updatedate;
    this.updateText = updatetext;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
