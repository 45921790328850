// ---------------------------------------------------------------------
// <copyright file="under-button.component.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Subscription } from 'rxjs';
import { DisplayStatus, DividedSteps } from 'src/app/models/step.enum';
import { ButtonService } from 'src/app/services/button.service';
import { HttpService } from 'src/app/services/http.service';
import { ProcessService } from 'src/app/services/process.service';

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

/**
 * 下部のボタンを表示するかどうか、ボタンの押した通知を読み取るコンポーネント
 * 診断画像の保存、削除もここで連動している
 */

@Component({
  selector: 'app-under-button',
  templateUrl: './under-button.component.html',
  styleUrls: ['./under-button.component.css'],
})
export class UnderButtonComponent implements OnInit, OnChanges, OnDestroy {
  @Input() nowStep: string;
  @Input() display: DisplayStatus;
  @Input() errorMessage: string;
  @Input() errorMessageFlag: boolean;
  @Output() NextStep: EventEmitter<DividedSteps> =
    new EventEmitter<DividedSteps>();
  @Output() retryFlag: EventEmitter<boolean> = new EventEmitter<boolean>();

  public disabled: boolean;
  public testProcess: string;
  private subscribeButton: Subscription;

  constructor(
    private button: ButtonService,
    private httpService: HttpService,
    private changeDetect: ChangeDetectorRef,
    private process: ProcessService
  ) {}

  ngOnInit(): void {
    this.subscribeButton = this.button
      .observeDisabled()
      .subscribe((disabled) => {
        this.disabled = disabled;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const step = DividedSteps[this.nowStep];
  }

  ngOnDestroy(): void {
    this.subscribeButton.unsubscribe();
  }

  visibilityErrorMessageBox(): string {
    if (this.errorMessageFlag === true) {
      return 'ErrorMessageBox';
    }
    return 'hidden';
  }

  visibilityOneButton(): string {
    if (this.display === DisplayStatus.One) {
      return 'OK';
    }
    return 'hidden';
  }

  visibilityTwoButton(): string {
    if (this.display === DisplayStatus.Two) {
      return 'two-button';
    }
    return 'hidden';
  }

  visibilityRetryButton(): string {
    if (this.errorMessageFlag === true) {
      return 'Retry';
    }
    return 'hidden';
  }

  submitClickEvent(event: any): void {
    console.log('submitClickEvent Occured!');
    this.button.clickEventNext(true);
    this.NextStep.emit(DividedSteps[this.nowStep] + 1);
    this.button.clickEventNext(false);
  }

  submitSaveClickEvent(event: any): void {
    this.button.clickEventNext(true);
    // 診断結果画像を別データストアに保存する

    let testProcess = localStorage.getItem('TestProcess');
    let machineID = localStorage.getItem('MachineID');

    let body = {
      machineID: machineID,
      testProcess: testProcess,
    };
    ////////// WEB API //////////
    this.httpService
      .httpPostRequest('/anomalypreproimagesave', body)
      .subscribe((response) => {
        console.log(response);
        console.log('WebAPI /anomalypreproimagesave Complete.');

        //一時的な診断結果画像をデータストアから削除する

        ////////// WEB API //////////
        this.httpService
          .httpPostRequest('/anomalypreproimagedelete', body)
          .subscribe((response) => {
            console.log(response);
            console.log('WebAPI /anomalypreproimagedelete Complete.');
          });
      });
    /////////////////////////////

    /////////////////////////////
    this.NextStep.emit(DividedSteps[this.nowStep] + 1);
    this.button.clickEventNext(false);
  }
  submitRetryClickEvent(event: any): void {
    this.button.clickEventNext(true);
    this.retryFlag.emit(true);
    this.button.clickEventNext(false);
  }

  resetInspect(): void {
    this.button.clickEventNext(true);
    // 診断結果画像を参照データストアから削除する
    let testProcess = localStorage.getItem('TestProcess');
    let machineID = localStorage.getItem('MachineID');
    let body = {
      machineID: machineID,
      testProcess: testProcess,
    };
    ////////// WEB API //////////
    this.httpService
      .httpPostRequest('/anomalypreproimagedelete', body)
      .subscribe((response) => {
        console.log(response);
        console.log('WebAPI /anomalypreproimagedelete Complete.');
      });
    /////////////////////////////
    this.NextStep.emit(DividedSteps.Step5_1);
    this.button.clickEventNext(false);
  }
}
