// ---------------------------------------------------------------------
// <copyright file="asset-select.component.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Subscription } from 'rxjs';
import { Asset } from 'src/app/models/asset';
import { api_asset_box, asset_box } from 'src/app/models/asset-box';
import { ProcessService } from 'src/app/services/process.service';

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

/**
 * Assetsを手動入力するコンポーネント
 */
@Component({
  selector: 'app-asset-select',
  templateUrl: './asset-select.component.html',
  styleUrls: ['./asset-select.component.css'],
})
export class AssetSelectComponent implements OnInit, OnDestroy {
  public Select_Flug = {
    values: [
      { text: '有効', value: true },
      { text: '無効', value: false },
    ],
  };

  public init_assets: Asset[];
  public list_assets: asset_box[] = new Array();
  public list_assets_new: api_asset_box[] = new Array();
  private subscribeAssets: Subscription;

  @Output() apiassets: EventEmitter<any> = new EventEmitter<any>();

  constructor(private processService: ProcessService) {}

  ngOnInit(): void {
    // Assetsを読み込み、使いやすいようにlist_assetsに必要項目だけ取り出す
    this.subscribeAssets = this.processService
      .observeAssets()
      .subscribe((assets) => {
        this.init_assets = assets;
        for (let asset of this.init_assets) {
          let temp_box = {
            AssetName: asset.AssetName,
            AxisNum: asset.AxisNum,
            flag: true,
          };
          this.list_assets.push(temp_box);
        }
      });
    // EventEmitterをページ読み込み段階で実行する
    this.selectAsset();
  }

  ngOnDestroy(): void {
    this.subscribeAssets.unsubscribe();
  }

  // 選択するかどうかでボタンのCSSを変更する
  selectedCSS(value, option: string): string {
    if (value === option) {
      return 'selected';
    }
    return 'not-selected';
  }

  // 有効にするAssetsのListを作成する
  selectAsset(assetName?, value?): void {
    this.list_assets_new = [];
    for (var i = 0; i < this.list_assets.length; i++) {
      if (this.list_assets[i].AssetName === assetName) {
        this.list_assets[i].flag = value;
      }
    }
    for (var i = 0; i < this.list_assets.length; i++) {
      if (this.list_assets[i].flag === true) {
        let temp_box = {
          AssetName: this.list_assets[i].AssetName,
          AxisNum: this.list_assets[i].AxisNum,
        };
        this.list_assets_new.push(temp_box);
      }
    }
    // 作成した有効AssetsのListをログ出力する
    console.log('list_asset_new');
    console.log(this.list_assets_new);

    this.apiassets.emit(this.list_assets_new);
  }
}
