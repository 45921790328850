<div class="search">
  <div class="row-search">
    <div class="id-search">
      <select class="machine-list" [(ngModel)]="selectedMachineID">
        <option [value]="undefined" selected disabled>機番</option>
        <option *ngFor="let machine of machineList" [value]="machine.MachineID">
          {{machine.MachineID}}
        </option>
      </select>
      <input type="text" class="machine-id" [(ngModel)]="inputMachineID">
    </div>
    <img [src]="search">
  </div>
  <span class="row-search">
    <div class="date-search">
      期間
      <input type="date" class="date" [(ngModel)]="startDate">
      から<input type="date" class="date" [(ngModel)]="endDate" min="{{startDate}}">
      まで
    </div>
  </span>
</div>
