// ---------------------------------------------------------------------
// <copyright file="comment.component.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Component, Input, OnInit } from '@angular/core';

/**
 * 狛犬がヘッダーの下あたりでコメントを喋る画面を表示するコンポーネント
 */
@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css'],
})
export class CommentComponent implements OnInit {
  public readonly dogPath = encodeURI('assets/komainu_icon/柴犬のイラスト.png');
  @Input() step: string;
  @Input() comment: string;
  @Input() machineID: string;
  @Input() disabled: boolean;

  constructor() {}

  ngOnInit(): void {}

  displayMachineID(step: string): string {
    if (step === 'Step3_1') {
      return 'machine-id';
    } else if (step === 'Step3_2') {
      return 'machine-id';
    }
    return 'hidden';
  }
}
