// ---------------------------------------------------------------------
// <copyright file="machine-spec.component.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { MachineSpec } from 'src/app/models/machine-spec';
import { HttpService } from 'src/app/services/http.service';
import { ProcessService } from 'src/app/services/process.service';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

/**
 * 機械仕様をDBから読み取り、手動入力するコンポーネント
 */
@Component({
  selector: 'app-machine-spec',
  templateUrl: './machine-spec.component.html',
  styleUrls: ['./machine-spec.component.css'],
})
export class MachineSpecComponent implements OnInit {
  public spindle = {
    type: '主軸タイプ',
    values: [
      { text: 'Power\nMaster', value: 'Power' },
      { text: 'Speed\nMaster', value: 'Speed' },
      { text: 'その他', value: 'Other' },
    ],
  };
  public speed = { type: '主軸回転数', values: [] };
  public assetB = {
    type: 'B軸仕様',
    values: [
      { text: '任意割り出し', value: 'Arbitrary' },
      { text: '一度割り出し', value: 'Ichido' },
    ],
  };
  public jig = {
    type: '治具',
    values: [
      { text: 'あり', value: true },
      { text: 'なし', value: false },
    ],
  };
  public machineSpec: MachineSpec = new MachineSpec();
  @Input() machineID: string;
  // MachineSpecにアクションがあった際、machineSpecEventが起動する。
  // machine-registerコンポーネントに値が渡される。
  @Output() machineSpecEvent: EventEmitter<MachineSpec> =
    new EventEmitter<MachineSpec>();
  @Output() assets: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private httpService: HttpService,
    private processService: ProcessService
  ) {}

  ngOnInit(): void {
    ////////// WEB API //////////
    this.httpService
      .httpGetRequest<any>('/machinetypeinfoget/' + this.machineID)
      .subscribe(
        (response) => {
          console.log(response);
          this.machineSpec = new MachineSpec(response); //ここにデータ入れないと更新されない
          this.machineSpec.SpindleSpeed = undefined; //余計な0が入力されないように
          this.machineSpecEvent.emit(this.machineSpec);
          this.assets.emit(response.Assets);
          this.processService.operationPhaseNext(
            this.machineSpec.OperationPhase
          );
        },
        () => {
          console.log(`WebAPI /machinetypeinfoget/ Completed.}`);
        }
      );
    ////////// WEB API //////////
  }

  selectedSpecCSS(value, option: string): string {
    if (value === option) {
      return 'selected';
    }
    return 'not-selected';
  }

  inputSpeed(event: Event): void {
    this.machineSpecEvent.emit(this.machineSpec);
  }

  selectSpec(item, option): void {
    if (item.type === '主軸タイプ') {
      this.machineSpec.SpindleType = option;
    } else if (item.type === 'B軸仕様') {
      this.machineSpec.BSpindleTable = option;
    } else if (item.type === '治具') {
      this.machineSpec.JigExist = option;
    } else {
    }
    this.machineSpecEvent.emit(this.machineSpec);
  }
}
