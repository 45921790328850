<div class="upper">
  <div class="title">検査結果一覧</div>
</div>

<body>
  <div *ngIf="!loading">
    <table class="table table-hover">
      <thead>
        <!-- 表の見出し全体 -->
        <tr class="td-short">
          <ng-container *ngFor="let column of titlenames">
            <th>
              <!-- 見出しの各セル -->
              <!--クラス・IDに番号をつける -->
              <div class="box-column">{{ column }}</div>
            </th>
          </ng-container>
        </tr>
      </thead>

      <tbody>
        <!-- 表の内容 -->
        <!-- machineListではなくtypesccriptで表示用に成型したリストdisplayMachineListを渡す-->
        <ng-container *ngFor="let machine of machineList">
          <tr class="tr1">
            <td class="td1">
              <button type="button" (click)="clickMachine(machine.MachineID)">
                {{ machine.MachineID }}
              </button>
            </td>

            <!-- machine名前 -->
            <ng-container
              *ngFor="let item of machine.TestProcessList | keyvalue"
            >
              <tr>
                <!-- 工程 -->
                <td class="td2">
                  <div class="box-testprocess">
                    {{ item?.key }}
                  </div>
                </td>

                <!-- 検査日 -->
                <td class="td3">
                  <div class="box-date">
                    {{ item.value.LastUpdate | date : "y/M/d EEE" }}
                  </div>
                </td>

                <!-- 判定 -->
                <td class="td4">
                  <div
                    *ngIf="
                      getJudgement(machine.MachineID, item.key);
                      else falseJudgement
                    "
                  >
                    <div class="box-judgement-ok">OK</div>
                  </div>

                  <ng-template #falseJudgement>
                    <div class="box-judgement-ng">NG</div>
                  </ng-template>
                </td>

                <!-- 担当 -->
                <td class="td5">
                  <div class="box-operator">
                    {{ item.value.Operator | usernameFirst }}
                  </div>
                </td>

                <!-- 承認 -->
                <td class="td6">
                  <div class="box-approver">
                    {{ item.value.Approver | usernameFirst }}
                  </div>
                </td>
              </tr>
            </ng-container>

            <!-- コメント -->
            <td class="td7">
              <div>{{ machine.CommentNumber | commentzero }}</div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>

    <div class="pagination-container">
      <ul class="pagination">
        <!-- ページが4ページ以上の場合 -->
        <div *ngIf="pageTotalNumber > 3; else showAllPageIndex">
          <!-- 最初のページを表示させる場合 -->
          <div
            class="buttons"
            *ngIf="currentPageIndex == 0; else pageIndexLast"
          >
            <button class="active" (click)="clickPage(currentPageIndex)">
              {{ currentPageIndex + 1 }}
            </button>
            <button (click)="clickPage(currentPageIndex + 1)">
              {{ currentPageIndex + 2 }}
            </button>
            <span class="ellipsis">&hellip;</span>
            <button class="arrow" (click)="navigateToNextPage()">
              &raquo;
            </button>
          </div>

          <!-- 最後のページを表示させる場合 -->
          <ng-template #pageIndexLast>
            <div
              class="buttons"
              *ngIf="
                currentPageIndex == pageTotalNumber - 1;
                else pageIndexOthers
              "
            >
              <button class="arrow" (click)="navigateToPreviousPage()">
                &laquo;
              </button>
              <span class="ellipsis">&hellip;</span>
              <button (click)="clickPage(currentPageIndex - 1)">
                {{ currentPageIndex }}
              </button>
              <button class="active" (click)="clickPage(currentPageIndex)">
                {{ currentPageIndex + 1 }}
              </button>
            </div>

            <!-- 中間のページを表示させる場合 -->
            <ng-template #pageIndexOthers>
              <div class="buttons">
                <button class="arrow" (click)="navigateToPreviousPage()">
                  &laquo;
                </button>
                <div *ngIf="currentPageIndex - 1 !== 0">
                  <span class="ellipsis;">&hellip;</span>
                </div>
                <button (click)="clickPage(currentPageIndex - 1)">
                  {{ currentPageIndex }}
                </button>
                <button class="active" (click)="clickPage(currentPageIndex)">
                  {{ currentPageIndex + 1 }}
                </button>
                <button (click)="clickPage(currentPageIndex + 1)">
                  {{ currentPageIndex + 2 }}
                </button>
                <div *ngIf="currentPageIndex + 1 !== pageTotalNumber - 1">
                  <span class="ellipsis;">&hellip;</span>
                </div>
                <button class="arrow" (click)="navigateToNextPage()">
                  &raquo;
                </button>
              </div>
            </ng-template>
          </ng-template>
        </div>

        <!-- ページが3ページ以下の場合 -->
        <ng-template #showAllPageIndex>
          <li *ngFor="let index of pageNumberList">
            <button [class]="pageActive(index)" (click)="clickPage(index)">
              {{ index + 1 }}
            </button>
          </li>
        </ng-template>
      </ul>
    </div>
  </div>
</body>
<app-display-loading [loading]="loading"></app-display-loading>
<app-result-button
  [mode]="'list'"
  (event)="clickTop($event)"
></app-result-button>
