// ---------------------------------------------------------------------
// <copyright file="machine-register.component.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Subscription } from 'rxjs';
import { Asset } from 'src/app/models/asset';
import { api_asset_box } from 'src/app/models/asset-box';
import { MachineSpec } from 'src/app/models/machine-spec';
import { AuthService } from 'src/app/services/auth.service';
import { ButtonService } from 'src/app/services/button.service';
import { HttpService } from 'src/app/services/http.service';
import { ProcessService } from 'src/app/services/process.service';

import { Component, OnDestroy, OnInit } from '@angular/core';

/**
 * 機番の読み取り、DBから取得、手動変更、登録を行うコンポーネント
 */
@Component({
  selector: 'app-machine-register',
  templateUrl: './machine-register.component.html',
  styleUrls: ['./machine-register.component.css'],
})
export class MachineRegisterComponent implements OnInit, OnDestroy {
  public step: string;
  public machineID: string;
  public username: string;
  public testProcess: string;
  public Step3_box = {
    first: 'Step3_1',
    second: 'Step3_2',
    third: 'Step3_3',
  };
  public TDTA: number;

  private machineSpec: MachineSpec;
  private subscribeStep: Subscription;
  private subscribeButton: Subscription;
  private apiAssets: api_asset_box[];

  constructor(
    private button: ButtonService,
    private http: HttpService,
    private processService: ProcessService,
    private authService: AuthService
  ) {
    this.username = this.authService.username;
    this.machineID = localStorage.getItem('MachineID');
    this.testProcess = localStorage.getItem('TestProcess');
    console.log('NTX Check!');
    console.log(this.machineID.slice(0, 2));
    if (this.machineID.slice(0, 2) === 'NTX') {
      this.TDTA = 800;
    } else {
      this.TDTA = 100;
    }
  }

  ngOnInit(): void {
    this.subscribeStep = this.processService.observeStep().subscribe((step) => {
      this.step = step;
      if (step === 'Step3_1') {
        this.button.disabledNext(true);
      } else {
        this.button.disabledNext(true);
      }
    });
    this.subscribeButton = this.button
      .observeClickEvent()
      .subscribe((click) => {
        if (click && this.step === 'Step3_1') {
          this.processService.machineIDNext(this.machineID);
        } else if (click && this.step === 'Step3_2') {
          console.log('Step3_3 click and REST API');
          const compactPCID = localStorage.getItem('CompactPC');
          const ipAddress = localStorage.getItem('IPAddress');
          const subnetMask = localStorage.getItem('SubnetMask');
          const portNumber = localStorage.getItem('PortNumber');

          // WEB APIでpostする際のbodyを用意する
          let body1 = {
            machineID: this.machineID,
            compactPCID: compactPCID,
            spindleType: this.machineSpec.SpindleType,
            spindleSpeed: this.machineSpec.SpindleSpeed,
            bSpindleTable: this.machineSpec.BSpindleTable,
            jigExist: this.machineSpec.JigExist,
            operationPhase: this.machineSpec.OperationPhase,
            inspector: this.username,
            edgeConfig: {
              IPAddress: ipAddress,
              SubnetMask: subnetMask,
              PortNum: portNumber,
              OneShotFlag: 0,
              OneShotInterval: 3600,
              TriggerDataTableAddress: this.TDTA,
            },
            assets: this.apiAssets,
            testProcess: this.testProcess,
          };

          let body2 = {
            machineID: this.machineID,
            compactPCID: compactPCID,
            testProcess: this.testProcess,
          };

          ////////// WEB API //////////
          this.http
            .httpPostRequest<any>('/machinespecificinfoput', body1)
            .subscribe(
              (response) => {
                console.log(`WebAPI /machinespecificinfoput Completed.}`);
                console.log(response);
                ////////// WEB API //////////
                this.http
                  .httpPostRequest<any>('/machineinfojsonput', body2)
                  .subscribe(
                    (response) => {
                      console.log(response);
                      console.log(`WebAPI /machineinfojsonput Completed.}`);
                      this.processService.isCompleteJsonPutNext(true);
                    },
                    () => {}
                  );
                /////////////////////////////
              },
              () => {}
            );
          /////////////////////////////
        } else if (click && this.step === 'Step3_3') {
        } else {
        }
      });
  }
  ngOnDestroy(): void {
    this.subscribeStep.unsubscribe();
    this.subscribeButton.unsubscribe();
  }

  qrcodeAccept(event: string): void {
    this.machineID = event;
    this.button.disabledNext(false);
  }

  setMachineSpec(machineSpec: MachineSpec): void {
    this.machineSpec = machineSpec;
    this.processService.operationPhaseNext(machineSpec.OperationPhase);
    this.button.disabledNext(this.machineSpec.isUndefined());
  }

  setAssets(assets: Asset[]): void {
    console.log(assets);
    this.processService.assetsNext(assets);
  }

  setApiAssets(apiAssets: api_asset_box[]): void {
    console.log(apiAssets);
    this.apiAssets = apiAssets;
    this.processService.apiAssetsNext(apiAssets);
    this.button.disabledNext(false);
  }
}
