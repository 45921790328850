// ---------------------------------------------------------------------
// <copyright file="username-first.pipe.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'usernameFirst',
})
export class UsernameFirstPipe implements PipeTransform {
  transform(username: string): unknown {
    return username.substr(0, username.indexOf('_'));
  }
}
