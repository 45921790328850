// ---------------------------------------------------------------------
// <copyright file="icon-image.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

//  CompactPCSTatusをここで読み出しちゃおう

export class IconImage {
  static readonly TargetDir: string = '/assets/komainu_icon';
  typeName: string;
  isGif: boolean;
  enable: boolean;

  // 初期化時に、アイコンの名前とComapctPCStatusの状態を渡す
  constructor(typeName: string, isGif: boolean) {
    this.typeName = typeName;
    this.isGif = isGif;
    this.enable = false;
  }
  // アイコンのパスを生成する
  public fullPath(): string {
    let path: string = IconImage.TargetDir + '/' + this.typeName;
    path += this.enable ? '_true' : '_false';
    path += this.isGif ? '.gif' : '.png';
    return path;
  }

  public setEnable(isEnable: boolean): void {
    this.enable = isEnable;
  }
}
