// ---------------------------------------------------------------------
// <copyright file="machine-spec.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

/**
 * 機械の仕様項目をまとめたモデル、一度DBで値が入力されるが、手動で適宜変更する
 */
export class MachineSpec {
  SpindleType: string;
  SpindleSpeed: number;
  BSpindleTable: string;
  JigExist: boolean;
  MachineType: string;
  OperationPhase: string;

  constructor(spec?: any) {
    if (spec) {
      this.SpindleType = spec.DefaultSpec.SpindleType;
      this.SpindleSpeed = spec.DefaultSpec.SpindleSpeed;
      this.BSpindleTable = spec.DefaultSpec.BSpindleTable;
      this.JigExist = spec.DefaultSpec.JigExist;
      this.MachineType = spec.MachineType;
      this.OperationPhase = spec.OperationPhase;
      console.log(`SpindleType: ${this.SpindleType}`);
      console.log(`SpindleSpeed: ${this.SpindleSpeed}`);
      console.log(`BSpindleTable: ${this.BSpindleTable}`);
      console.log(`JigExist: ${this.JigExist}`);
      console.log(`MachineType: ${this.MachineType}`);
      console.log(`OperationPhase: ${this.OperationPhase}`);
    }
  }

  isUndefined(): boolean {
    if (
      !this.SpindleType ||
      this.SpindleSpeed === undefined ||
      !this.BSpindleTable ||
      this.JigExist === undefined
    ) {
      return true;
    }
    return false;
  }
}
