// ---------------------------------------------------------------------
// <copyright file="header.component.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { CompactPCStatus } from 'src/app/models/compact-pc-status';

import { Component, Input, OnInit } from '@angular/core';

/**
 * 画面上部の恒常的に表示するヘッダーの総括を行うコンポーネント、データデバイディングのみ行う
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  public readonly homePath = encodeURI(
    'assets/komainu_icon/家の無料アイコン.png'
  );
  @Input() nowStep: string;
  @Input() compactPCID: string;
  @Input() machineID: string;
  @Input() compactPCStatus: CompactPCStatus;
  @Input() operationPhase: string;
  @Input() testProcess: string;
  public username: string;

  constructor() {}

  ngOnInit(): void {}
}
