// ---------------------------------------------------------------------
// <copyright file="komainu.pipe.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toKomainu',
})
export class KomainuPipe implements PipeTransform {
  transform(compactPC: string): unknown {
    console.log(compactPC);
    const re = /Cosima*/;
    if (this.isExistCosima(compactPC, re)) {
      const result = compactPC.replace(re, 'Komainu');
      return result;
    }
    return compactPC;
  }

  private isExistCosima(compactPC: string, re: RegExp): boolean {
    return re.test(compactPC);
  }
}
