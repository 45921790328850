// ---------------------------------------------------------------------
// <copyright file="header-info.component.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { CompactPCStatus } from 'src/app/models/compact-pc-status';
import { IconImage } from 'src/app/models/icon-image';
import { OperationPhaseMap } from 'src/app/models/operation-phase';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';


import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

/**
 * 検査工程で取得される情報や、各デバイスとの通信状態のステータス、工程フローを表示するコンポーネント
 */
@Component({
  selector: 'app-header-info',
  templateUrl: './header-info.component.html',
  styleUrls: ['./header-info.component.css'],
})
export class HeaderInfoComponent implements OnInit {
  @Input() compactPCID: string;
  @Input() machineID: string;
  @Input() compactPCStatus: CompactPCStatus;
  @Input() operationPhase: string;
  @Input() connectionStatus: boolean;
  @Input() connectionMessage: string;

  networkStatus: boolean = false;
  networkStatus$: Subscription = Subscription.EMPTY;
  private operationPhaseMap: OperationPhaseMap = new OperationPhaseMap();

  public operationPhaseColor: string;
  public _operationPhase: string;
  public operationPhaseName: string;

  public _compactPCStatus: CompactPCStatus = new CompactPCStatus();

  public iconPaths: IconImage[] = [
    new IconImage('compactpc', false),
    new IconImage('nc', false),
    new IconImage('komainu_anime', true),
  ];

  // 常時表示アイコンのパスを読み出す
  public readonly iPadIcon = encodeURI('/assets/komainu_icon/iPad.png');

  constructor() {
    this._compactPCStatus.init();
  }
  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.operationPhase === undefined) {
      this.operationPhase = 'Init';
    }
    this.operationPhaseColor =
      this.operationPhaseMap.operationPhaseMap[this.operationPhase].color; // 背景色を変更
    this.operationPhaseName =
      this.operationPhaseMap.operationPhaseMap[this.operationPhase].type; // 運用名を変更

    //compactPCStatusの値から、iconPathsをセット
    this._compactPCStatus = this.compactPCStatus;
    this.iconPaths[0].setEnable(this._compactPCStatus.OnlineStatus);
    this.iconPaths[1].setEnable(this._compactPCStatus.NCConnectivity);
    this.iconPaths[2].setEnable(this._compactPCStatus.DataCollection);
    
    //Internet接続状態を確認
    this.checkNetworkStatus();
  }

  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        console.log('status', status);
        this.networkStatus = status;
        if(status){
          this.connectionMessage = "";
        }
        else {
          this.connectionMessage = "ブラウザからネットワークに接続できません。";
        };
        

      });
  }
  
}