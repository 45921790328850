// ---------------------------------------------------------------------
// <copyright file="app-info.component.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------
import { Observable, of, Subscription } from 'rxjs';
import { CompactPCStatus } from 'src/app/models/compact-pc-status';
import { Comment, DisplayStatus, DividedSteps } from 'src/app/models/step.enum';
import { CompactPcService } from 'src/app/services/compact-pc.service';
import { HttpService } from 'src/app/services/http.service';
import { ProcessService } from 'src/app/services/process.service';

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

/**
 * 検査工程の親コンポーネント、親子間のデータ受け渡し、画面遷移の実行を行う。
 */
@Component({
  selector: 'app-inspect-process',
  templateUrl: './inspect-process.component.html',
  styleUrls: ['./inspect-process.component.css'],
})
export class InspectProcessComponent implements OnInit, OnDestroy {
  public step: string;
  public compactPCID: string;
  public machineID: string;
  public jobID: string;
  public comment: Comment;
  public operationPhase: string;
  public testProcess: string;

  public compactPCStatus: CompactPCStatus = new CompactPCStatus();

  private subscribeStep: Subscription;
  private subscribeCompactPCID: Subscription;
  private subscribeMachineID: Subscription;
  private subscribeJob: Subscription;
  private subscribeOperationPhase: Subscription;
  private subscribeTestProcess: Subscription;
  private timer;
  private timerWatchDog;

  constructor(
    private processService: ProcessService,
    private router: Router,
    private http: HttpService,
    private compactPCService: CompactPcService,
    private changeDetect: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscribeStep = this.processService.observeStep().subscribe((step) => {
      if (step === undefined) {
        step = 'Step0_1';
        this.processService.stepNext(step);
      }
      clearInterval(this.timer);
      this.step = step;
      this.comment = Comment[step];
      if (Number(DividedSteps[step]) > DividedSteps.Step3_1) {
        // 3秒ごとにcompactPCStatusが更新される
        this.timer = setInterval(
          () => {
            // TODO: jobIDの型の扱いを決定しておく
            // 暫定として、nullではめる
            if (this.jobID == undefined) {
              this.jobID = null;
            }
            ////////// WEB API //////////
            this.http
              .httpGetRequest<CompactPCStatus>(
                `/compactpcstatusget/${this.compactPCID}?jobid=${this.jobID}`
              )
              .subscribe(
                (response) => {
                  // console.log(response);
                  this.compactPCStatus = new CompactPCStatus(response);
                  this.compactPCService.onlineStatusNext(response.OnlineStatus);
                  this.compactPCService.jobStatusNext(response.JobStatus);
                  this.compactPCService.ncConnectivityNext(
                    response.NCConnectivity
                  );
                  this.compactPCService.dataCollectionNext(
                    response.DataCollection
                  );
                  console.log(this.compactPCStatus);
                },
                (error) => {
                  console.log(error);
                  console.log(`WebAPI /compactpcstatusget/ Error Occured!`);
                },
                () => {
                  //console.log(`WebAPI /compactpcstatusget/ Completed.`);
                }
              );
          },
          /////////////////////////////
          3000
        );
      }
      this.changeDetect.detectChanges(); // 変更通知
    });
    this.http.getWatchDog().subscribe((response) => console.log(response));
    //30秒ごとにWatchDogが起動し、CompanctPC Statusを全てFalseにする

    this.timerWatchDog = setInterval(
      () => {
        ////////// WEB API //////////
        this.http.getWatchDog().subscribe(
          (response) => {
            console.log(response);
          },
          (error) => {
            console.log(`WebAPI getwatchdog Error Occured!`);
          },
          () => {
            console.log(`WebAPI getwatchdog Completed.`);
          }
        );
      },
      /////////////////////////////
      30000
    );

    this.subscribeCompactPCID = this.processService
      .observeCompactPC()
      .subscribe((compactID) => {
        this.compactPCID = compactID;
      });

    this.subscribeMachineID = this.processService
      .observeMachineID()
      .subscribe((machineID) => {
        this.machineID = machineID;
      });

    this.subscribeOperationPhase = this.processService
      .observeOperationPhase()
      .subscribe((operationPhase) => {
        this.operationPhase = operationPhase;
      });

    this.subscribeJob = this.processService
      .observeJobID()
      .subscribe((jobID) => {
        this.jobID = jobID;
      });

    this.subscribeTestProcess = this.processService
      .observeTestProcess()
      .subscribe((testProcess) => {
        this.testProcess = testProcess;
      });
  }

  ngOnDestroy(): void {
    this.subscribeStep.unsubscribe();
    this.subscribeCompactPCID.unsubscribe();
    this.subscribeMachineID.unsubscribe();
    this.subscribeJob.unsubscribe();
    this.subscribeOperationPhase.unsubscribe();
    this.subscribeTestProcess.unsubscribe();

    clearInterval(this.timer);
    clearInterval(this.timerWatchDog);
  }

  commentDisabled(step: string): boolean {
    if (DividedSteps[step] > DividedSteps.Step5_1) {
      return true;
    }
    return false;
  }

  receiveStep(step: DividedSteps): void {
    const nowURLPath = this.stepSub(this.step);
    const nextURLPath = this.stepSub(DividedSteps[step]);
    this.processService.stepNext(DividedSteps[step]);
    if (nowURLPath !== nextURLPath) {
      this.router.navigate(['/' + nextURLPath.toLowerCase()]);
    }
  }

  stepSub(step: string): string {
    return step.substring(0, 5);
  }

  stepToDisplayButton(step: string): DisplayStatus {
    if (step === 'Step4_2' || step === 'Step4_4' || step === 'Step5_2') {
      return DisplayStatus.None;
    } else if (step === 'Step5_3') {
      return DisplayStatus.Two;
    }
    return DisplayStatus.One;
  }
}
