// ---------------------------------------------------------------------
// <copyright file="header-user.component.ts" company="WALC Inc."
// @author Naoya Abe, Melvin Bender
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ProcessService } from 'src/app/services/process.service';

import { Component, Input, OnInit } from '@angular/core';

/**
 * 検査担当者の名前と、ホームへ戻るボタンを表示するコンポーネント
 */
@Component({
  selector: 'app-header-user',
  templateUrl: './header-user.component.html',
  styleUrls: ['./header-user.component.css'],
})
export class HeaderUserComponent implements OnInit {
  @Input() username: string;
  @Input() homePath: string;
  @Input() testProcess: string;

  constructor(private auth: AuthService) {}

  ngOnInit(): void {
    this.username = this.auth.username;
  }

  ngOnChanges(): void {}
}
