<div class="upper"></div>
<img [src]="dogPath" class="image">
<app-app-info></app-app-info>


<ng-container *ngIf="state === status.Logout || state === status.NowLogin ; else signUpContent">

  <div class="center">
    <input name="username" type="text" autocomplete="off" placeholder="メールアドレス" [disabled]="state === status.NowLogin"
      [(ngModel)]="username">
  </div>
  <div class="center">
    <input name="password" type="password" autocomplete="off" placeholder="パスワード" [disabled]="state === status.NowLogin"
      [(ngModel)]="password">
  </div>
  <div class="center">
    <div class="alert" *ngIf="errorMessage != undefined">
      {{errorMessage}}
    </div>
  </div>

  <div class="center" *ngIf="state === status.Logout">
    <button (click)="clickLogin()">ログイン</button>
  </div>
  <div class="center" *ngIf="state === status.NowLogin">
    ログイン中・・・
  </div>
  <br>
  <br>
  <div class="center">
    <span class="sign-up" (click)="clickStatusSignUp()">ユーザ登録画面へ</span>
  </div>
</ng-container>

<ng-template #signUpContent>
  <ng-container *ngIf="state === status.SignUp ; else confirmSignUpContent">
    <div class="center">
      <input class="name" name="lastname" type="text" autocomplete="off" placeholder="姓" [(ngModel)]="lastName">
      <input class="name" name="firstname" type="text" autocomplete="off" placeholder="名" [(ngModel)]="firstName">
    </div>
    <div class="center">
      <input name="username" type="text" autocomplete="off" placeholder="メールアドレス" [disabled]="state === status.NowLogin"
        [(ngModel)]="username">
    </div>
    <div class="center">
      <input name="password" type="password" autocomplete="off" placeholder="パスワード" [(ngModel)]="password">
    </div>
    <div class="center">
      パスワードは "大文字、小文字、記号、数字を含む6文字から20文字" で入力してください。
    </div>
    <div class="center">
      <div class="alert" *ngIf="errorMessage != undefined">
        {{errorMessage}}
      </div>
    </div>
    <div class="center">
      <button (click)="clickSignUp()">登録</button>
    </div>
  </ng-container>

  <ng-template #confirmSignUpContent>
    <ng-container *ngIf="state === status.ConfirmSignUp">
      <div class="center">
        <input name="username" type="text" autocomplete="off" placeholder="メールアドレス" [(ngModel)]="username">
      </div>
      <div class="center">
        <input name="verifycode" type="text" autocomplete="off" placeholder="確認コード" [(ngModel)]="verifyCode">
      </div>
      <div class="center">
        <button (click)="clickConfirmSignUp()">コード認証</button>
      </div>
      <div class="center">
        <div class="alert" *ngIf="errorMessage != undefined">
          {{errorMessage}}
        </div>
      </div>
      <div class="center">
        <span class="sign-up" (click)="clickStatusSignIn()">ログイン画面へ</span>
      </div>
    </ng-container>
  </ng-template>