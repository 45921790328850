// ---------------------------------------------------------------------
// <copyright file="app-info.component.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { AuthService } from 'src/app/services/auth.service';
import { ProcessService } from 'src/app/services/process.service';

import { Component, OnInit } from '@angular/core';

/**
 * ホームページコンポーネント、このページに遷移するとLocalStorageの値が初期化される
 */
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  public readonly dogPath = encodeURI('assets/komainu_icon/柴犬のイラスト.png');
  public name: string;

  constructor(
    private processService: ProcessService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    // processServiceの値を初期化
    this.processService.resetInspectProcess();
    this.name = this.auth.username;
  }

  clickLogout(): void {
    this.auth.signOut();
  }
}
