// ---------------------------------------------------------------------
// <copyright file="comment-window.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

export class CommentList {
  CommentList: Comment[];
  sortedCommentList: Comment[];

  constructor(comments: Comment[]) {
    this.CommentList = [];
    for (const comment of comments) {
      this.CommentList.push(new Comment(comment));
    }
  }

  //日付の昇順に並び替え
  sortDateDown(): Comment[] {
    let _commentList = this.CommentList;
    this.sortedCommentList = _commentList.sort((n1, n2) => {
      if (n1.TimeStamp > n2.TimeStamp) {
        return 1;
      }

      if (n1.TimeStamp < n2.TimeStamp) {
        return -1;
      }
      return 0;
    });
    return this.sortedCommentList;
  }
}

export class Comment {
  MachineID: string;
  TimeStamp: string;
  Comment: string;
  TestProcess: string;
  User: string;

  constructor(comment: Comment) {
    this.MachineID = comment.MachineID;
    this.TimeStamp = comment.TimeStamp;
    this.Comment = comment.Comment;
    this.TestProcess = comment.TestProcess;
    this.User = comment.User;
  }
}
