// ---------------------------------------------------------------------
// <copyright file="header-title.component.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Steps } from 'src/app/models/step.enum';

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

export enum Titles {
  Step0 = 'ワゴンの準備',
  Step1 = 'NCの設定',
  Step2 = 'コンパクトPCの登録',
  Step3 = '機械の登録',
  Step4 = 'コンパクトPCとNCの接続',
  Step5 = '診断プログラムの実行',
}

/**
 * 作業工程の現在のステップを表示するコンポーネント
 */
@Component({
  selector: 'app-header-title',
  templateUrl: './header-title.component.html',
  styleUrls: ['./header-title.component.css'],
})
export class HeaderTitleComponent implements OnInit, OnChanges {
  public readonly stepNames = [
    'ワゴンの準備',
    'NCの設定',
    'コンパクトPC',
    '機械の登録',
    'PC→NC接続',
    '診断プログラム',
  ];
  public title: string;
  @Input() step: string;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.title = Titles[this.step];
  }

  styleStep(step: string): string {
    const stepIndex = this.stepNames.indexOf(step);
    const stepNowIndex = Steps[this.step];
    if (stepIndex === stepNowIndex) {
      return 'steps now-step';
    } else if (stepIndex > stepNowIndex) {
      return 'steps post-steps';
    } else {
      return 'steps pre-steps';
    }
  }

  displayStepName(step: string): string {
    const stepIndex = this.stepNames.indexOf(step);
    const stepNowIndex = Steps[this.step];
    if (stepIndex > stepNowIndex) {
      return '';
    }
    return step;
  }
}
