// ---------------------------------------------------------------------
// <copyright file="compact-pc-status.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

export class CompactPCStatus {
  OnlineStatus: boolean;
  JobStatus: string;
  NCConnectivity: boolean;
  DataCollection: boolean;

  constructor(status?: CompactPCStatus) {
    if (status) {
      this.OnlineStatus = status.OnlineStatus;
      this.JobStatus = status.JobStatus;
      this.NCConnectivity = status.NCConnectivity;
      this.DataCollection = status.DataCollection;
    } else {
      this.init();
    }
  }
  public init(): void {
    this.OnlineStatus = false;
    this.JobStatus = '';
    this.NCConnectivity = false;
    this.DataCollection = false;
  }
}
