<div class="outer">
  <zxing-scanner class="qrcode" previewFitMode="cover"
    [formats]="allowedFormats"
    [(device)]="currentCamera"
    (scanSuccess)="scanSuccessHandler($event)"
    (scanError)="scanErrorHandler($event)"
    (scanFailure)="scanFailureHandler()"
    (scanComplete)="scanCompleteHandler($event)"
    (camerasFound)="onCamerasFound($event)"
  ></zxing-scanner>

  <!-- カメラ切り替えボタン -->
  <button class="btn btn-color btn-size" (click)="onCameraChange()">カメラを切り替える</button>
  <p class="text-center">※外カメラにする際に複数回押す必要がある場合があります。</p>
</div>
