// ---------------------------------------------------------------------
// <copyright file="dialog-overview.component.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

/**
 * ダイアログで画像を表示するコンポーネント
 */
@Component({
  selector: 'app-dialog-overview',
  templateUrl: './dialog-overview.component.html',
  styleUrls: ['./dialog-overview.component.css'],
})
export class DialogOverviewComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { PreproSrc: string; ResultSrc: string; AssetKey: string }
  ) {
    console.log(this.data.AssetKey);
    console.log(this.data.PreproSrc);
    console.log(this.data.ResultSrc);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
