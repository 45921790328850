// ---------------------------------------------------------------------
// <copyright file="search-machine.component.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Component, Input, OnInit } from '@angular/core';

/**
 * 機械一覧画面で、データを検索するコンポーネント
 */
@Component({
  selector: 'app-search-machine',
  templateUrl: './search-machine.component.html',
  styleUrls: ['./search-machine.component.css'],
})
export class SearchMachineComponent implements OnInit {
  @Input() machineList;
  public selectedMachineID: string;
  public inputMachineID: string;
  public startDate: string;
  public endDate: string;
  public readonly search = encodeURI('/assets/komainu_icon/サーチアイコン.png');

  constructor() {}

  ngOnInit(): void {}
}
