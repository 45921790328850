<!-- ChatWindowが開いている場合 -->
<div *ngIf="isCommentWindowShow; else closedWindow">
    <div style="text-align:center">
        <button class="window-button" (click)="closeWindow()">閉じる</button>
    </div>
    <table class="all-comment-table">
        <tr *ngFor="let commentCell of commentList">
            <!-- コンポーネントのlistプロパティ -->
            <td>
                <table>
                    <tr>
                        <td colspan="3" class="date">{{ commentCell.TimeStamp | date:'y/M/d EEE, HH:mm'}}</td>
                    </tr>
                    <tr>
                        <td class="test-process">
                            <div class="box1">
                                <p>{{ commentCell.TestProcess }}</p>
                            </div>
                        </td>
                        <td class="user">
                            <div class="box1">
                                <p>{{ commentCell.User | usernameFirst }}</p>
                            </div>
                        </td>
                        <td class="comment-text">{{ commentCell.Comment }}</td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>

    <form [formGroup]="reactiveForm">
        <div class="container">
            <div>工程:{{testProcess}} 名前:{{username | usernameFirst}}</div>
            <input class="input-text" type="text" id="message" formControlName="message">
            <button (click)="sendComment()" [disabled]="reactiveForm.invalid">送信</button><br>
        </div>
    </form>
    <div>{{waitingMessage}}</div>
</div>
<!-- ChatWindowが閉じている場合 -->
<ng-template #closedWindow>
    <div style="text-align:center">
        <button class="window-button" (click)="openWindow()">コメント欄を拡大</button>
    </div>
    <table class="all-comment-table">
        <div *ngIf="isCommentExist;else noComment">
            <tr>
                <td colspan="3">{{ commentLatest.TimeStamp | date:'y/M/d EEE, HH:mm'}}</td>
            </tr>
            <tr>
                <td>
                    <div class="box1">
                        <p>{{ commentLatest.TestProcess }}</p>
                    </div>
                </td>
                <td>
                    <div class="box1">
                        <p>{{ commentLatest.User | usernameFirst }}</p>
                    </div>
                </td>
                <td>{{ commentLatest.Comment }}</td>
            </tr>
            <tr>
                <td colspan="3">
                    <div class="more-comment">more {{commentRestLength}} comments ... </div>
                </td>
            </tr>
        </div>
        <ng-template #noComment>
            <tr>
                <td>
                    No Comments.
                </td>
            </tr>
        </ng-template>
    </table>
</ng-template>