// ---------------------------------------------------------------------
// <copyright file="asset-status.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

export enum AssetStatus {
  normal,
  warning,
  critical,
}
