<h1 mat-dialog-title>NCネットワーク設定の確認方法</h1>
<div mat-dialog-content>
    <div [class]="stylePrePageing(nowPage)" (click)="previousPage(nowPage)">
        ◁
    </div>
    <div class="centerbox">
        <img [src]="imagePath[nowPage]">
        <div class="text-position">
            <div class="textbox" [innerHTML]="explanations[nowPage]"></div>
        </div>
        <button class="closebutton" (click)="onNoClick()">
            閉じる
        </button>
    </div>

    <div [class]="styleNextPageing(nowPage)" (click)="nextPage(nowPage)">
        ▷
    </div>
</div>