import { Auth } from 'aws-amplify';
import { from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { validateHorizontalPosition } from '@angular/cdk/overlay';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { ProcessService } from './process.service';

const CognitoIDPool = 'CognitoIdentityServiceProvider';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private expireToken: number;
  set ExpireToken(time: number) {
    this.expireToken = time;
  }
  private cognitoPool = environment.userPoolWebClientId;

  // enviromentごで保存されたAPIURLにアクセスする。
  private apiUrl = environment.apiurl;

  // main:    https://api.kotanapp.com
  // dev:     https://api.dev.kotanapp.com
  // feature: https://api.feature.kotanapp.com
  // local:   http://localhost:3000

  get getNowTimeUNIX(): number {
    return Date.now() / 1000;
  }
  get userID(): string {
    return this.auth.webStorage.getItem(
      `${CognitoIDPool}.${this.cognitoPool}.LastAuthUser`
    );
  }

  get idToken(): string {
    const key = `${CognitoIDPool}.${this.cognitoPool}.${this.userID}.idToken`;
    return this.auth.webStorage.getItem(key);
  }

  public getWatchDog(): Observable<any> {
    const response = this.http.get<any>(
      'https://fbqc7jcacxgtd6om2pc7nu3b3e0ivhvf.lambda-url.ap-northeast-1.on.aws/'
    );
    return response;
  }

  public sendErrorMessage(body): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
      }),
    };
    const response = this.httpPost<any>(
      'https://6fk4upeweczxmrrk5nrv3cfh6u0jvbhu.lambda-url.ap-northeast-1.on.aws/',
      body,
      httpOptions
    );
    return response;
  }

  private httpGet<T>(
    path: string,
    httpOptions: { headers: HttpHeaders }
  ): Observable<T> {
    const response = this.http.get<T>(this.apiUrl + path, httpOptions);
    return response;
  }

  // IDトークンをヘッダーにつけてAPIにPOSTする
  private httpPost<T>(
    path: string,
    body: any,
    httpOptions: { headers: HttpHeaders }
  ): Observable<T> {
    const response = this.http.post<T>(this.apiUrl + path, body, httpOptions);
    return response;
  }

  public httpGetRequest<T>(path: string): Observable<T> {
    let response: Observable<T>;
    if (this.expireToken < this.getNowTimeUNIX) {
      response = from(Auth.currentSession()).pipe(
        mergeMap((session) => {
          this.ExpireToken = session.getIdToken().getExpiration();
          const idToken = session.getIdToken().getJwtToken();
          const httpOptions = {
            headers: new HttpHeaders({
              Authorization: idToken,
              inspector: this.auth.email.split('@')[0],
            }),
          };
          console.log('httpOptions');
          console.log(httpOptions);
          return this.httpGet<T>(path, httpOptions);
        })
      );
    } else {
      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: this.idToken,
          inspector: this.auth.email.split('@')[0],
        }),
      };
      console.log('httpOptions');
      console.log(httpOptions);
      response = this.httpGet(path, httpOptions);
    }
    return response;
  }

  public httpPostRequest<T>(path: string, body): Observable<T> {
    let response: Observable<T | any> = new Observable(undefined);
    if (this.expireToken < this.getNowTimeUNIX) {
      response = from(Auth.currentSession()).pipe(
        mergeMap((session) => {
          this.ExpireToken = session.getIdToken().getExpiration();
          const idToken = session.getIdToken().getJwtToken();
          const httpOptions = {
            headers: new HttpHeaders({
              Authorization: idToken,
              inspector: this.auth.email.split('@')[0],
            }),
          };
          console.log('httpOptions');
          console.log(httpOptions);
          return this.httpPost(path, body, httpOptions);
        })
      );
    } else {
      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: this.idToken,
          inspector: this.auth.email.split('@')[0],
        }),
      };
      console.log('httpOptions');
      console.log(httpOptions);
      response = this.httpPost(path, body, httpOptions);
    }
    return response;
  }

  constructor(private http: HttpClient, private auth: AuthService) {}
}
