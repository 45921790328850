// ---------------------------------------------------------------------
// <copyright file="qr-testprocess.pipe.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'qrTestprocess',
})
export class QrTestprocessPipe implements PipeTransform {
  transform(testProcess: string): string {
    if (testProcess) {
      if (testProcess.length > 3) {
        // 検査看板の検査工程用に、パイプを作成する
        return testProcess.substr(0, testProcess.indexOf(' ')).slice(-3);
      } else {
        return testProcess;
      }
    } else {
      return testProcess;
    }
  }
}
