// ---------------------------------------------------------------------
// <copyright file="approval-window.component.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { MachineInfoComponent } from 'src/app/components/machine-info/machine-info.component';

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-approval-window',
  templateUrl: './approval-window.component.html',
  styleUrls: ['./approval-window.component.css'],
})
export class ApprovalWindowComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { message: string }) {}

  ngOnInit(): void {}
}
