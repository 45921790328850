// ---------------------------------------------------------------------
// <copyright file="nc-netconfig-photo.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

export const ImagePath = {
  Pre: encodeURI('/assets/process_image/pre.jpg'),
  NC1: encodeURI('/assets/process_image/nc_1.jpg'),
  NC2: encodeURI('/assets/process_image/nc_2.jpg'),
  NC3: encodeURI('/assets/process_image/nc_3.jpg'),
  NC4: encodeURI('/assets/process_image/nc_4.jpg'),
  NC5: encodeURI('/assets/process_image/nc_3.jpg'),
  NC6: encodeURI('/assets/process_image/nc_5.jpg'),
  NC7: encodeURI('/assets/process_image/nc_6.jpg'),
  LAN1: encodeURI('/assets/process_image/lan_1.jpg'),
  LAN2: encodeURI('/assets/process_image/lan_2.jpg'),
  LAN3: encodeURI('/assets/process_image/lan_3.jpg'),
} as const;

export const Explanations = {
  Pre: '出荷前検査キットを確認。AC電源を繋げ、コンパクトPCを起動させる。',
  NC1: '機械設定＞NC設定＞パラメータ画面>表示を押す。',
  NC2: '右下の＋を6回押して、“内蔵ポート”の項目を出す。',
  NC3: '“内蔵ポート”を押す。',
  NC4: 'キーボード操作で、IPアドレス、<br>サブネットマスクを変更可能<br>入力後にEnterキーを押す。',
  NC5: '左下の＜を押して、画面を戻し、<br>"FOCAS2"項目を押す。',
  NC6: 'TCP用ポート番号を入力して<br>Enterを押す。',
  NC7: '設定を変更した場合、NC設定画面を閉じて、<br>機械をシャットダウンし再度電源を入れる。<br>電源を入れている間に、次の工程を進めましょう。',
  LAN1: 'LANケーブルをNCの標準ポートに繋げる。<br>コンパクトPCから伸びているLANケーブルの端子を確認する。',
  LAN2: '配電盤を開け、FANUCのNC機を確認する。',
  LAN3: '画像中央のポートにLANケーブルを刺す。<br>もし他のLANが刺さっていたら抜いてから刺す。',
} as const;
