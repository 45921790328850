<div class="machine-info">
  <div class="left-box">
    <div class="compact-pc">
      <span>コンパクトPC</span>
      <span class="item-name">{{compactPCID | toKomainu}}</span>
    </div>
    <br>
    <div class="machine-id">
      <span>機番</span>
      <span class="item-name">{{machineID}}</span>
    </div>
  </div>
  <div class="icon-box">
    <img class="narrow" [src]="iPadIcon">
    <!-- CompactPCStatusの3つのパラメータごとに、アイコンの表示を変える -->
    <span *ngFor="let path of iconPaths">
      <img class="wide" [src]="path.fullPath()">
    </span>
    <br>
    <div class="connection-status">
      <span class="connection-message">{{connectionMessage}}</span>
    </div>
  </div>

  <div class="right-box">
    <span class="operation-flow">
      <div [style.background-color]="operationPhaseColor">
        {{operationPhaseName}}
      </div>
    </span>
  </div>
</div>