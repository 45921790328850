// ---------------------------------------------------------------------
// <copyright file="nc-network.component.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import {
    NcNetconfigPhotoComponent
} from 'src/app/component-parts/body/nc-netconfig-photo/nc-netconfig-photo.component';
import { NcNetworkConfig } from 'src/app/models/nc-network';
import { ButtonService } from 'src/app/services/button.service';
import { ProcessService } from 'src/app/services/process.service';

import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

/**
 * NC側の設定を行うコンポーネント
 */
@Component({
  selector: 'app-nc-network',
  templateUrl: './nc-network.component.html',
  styleUrls: ['./nc-network.component.css'],
})
export class NcNetWorkComponent implements OnInit {
  public isExistConfig = {
    type: 'NCネットワーク設定値が',
    values: [
      { text: 'ある', value: true },
      { text: 'ない', value: false },
    ],
  };
  public ipAddressSelections = {
    type: 'IPアドレス',
    values: [
      { text: '192.168.1.60', value: '192.168.1.60' },
      { text: '192.168.20.10', value: '192.168.20.10' },
      { text: '手入力', value: undefined },
    ],
    typing: false,
  };
  public subnetMaskSelections = {
    type: 'サブネットマスク',
    values: [
      { text: '255.255.255.0', value: '255.255.255.0' },
      { text: '手入力', value: undefined },
    ],
    typing: false,
  };
  public portNumberSelections = {
    type: 'ポート番号',
    values: [
      { text: '8193', value: '8193' },
      { text: '手入力', value: undefined },
    ],
    typing: false,
  };

  public ncNetworkConfig: NcNetworkConfig = new NcNetworkConfig();

  ipAddressInputFlag: boolean;
  subnetMaskInputFlag: boolean;
  portNumberInputFlag: boolean;

  constructor(
    public dialog: MatDialog,
    private processService: ProcessService,
    private buttonService: ButtonService
  ) {}

  ngOnInit(): void {
    // ページ読み込み時にデフォルト値に設定し、EventEmmiterを叩く
    this.ncNetworkConfig.IsExistConfig = false;
    this.initializeConfig();
    this.setNcNetworkConfig(this.ncNetworkConfig);
  }

  // ncNetworkConfigの更新を行う
  setNcNetworkConfig(ncNetworkConfig: NcNetworkConfig): void {
    this.ncNetworkConfig = ncNetworkConfig;
    console.log('ncNetworkConfig Changed!');
    console.log(this.ncNetworkConfig);
    this.processService.isExistConfigNext(this.ncNetworkConfig.IsExistConfig);
    this.processService.ipAddressNext(this.ncNetworkConfig.IPAddress);
    this.processService.subnetMaskNext(this.ncNetworkConfig.SubnetMask);
    this.processService.portNumberNext(this.ncNetworkConfig.PortNumber);
    this.buttonService.disabledNext(this.ncNetworkConfig.isUndefined());
  }

  // ネットワーク設定のデフォルト値設定
  initializeConfig(): void {
    this.ncNetworkConfig.IPAddress = '192.168.20.10';
    this.ncNetworkConfig.SubnetMask = '255.255.255.0';
    this.ncNetworkConfig.PortNumber = '8193';
  }

  // 選択するかどうかでボタンのCSSを変更する
  selectedCSS(value, option: string): string {
    if (value === option) {
      return 'selected';
    }
    return 'not-selected';
  }

  // 手入力ボタンの表示or非表示を変更する
  selectedInput(value): string {
    if (value === '') {
      return 'display';
    }
    return 'non-display';
  }

  // EventEmmiterを叩く
  inputIPAddress(event: Event): void {
    this.setNcNetworkConfig(this.ncNetworkConfig);
  }

  inputSubnetMask(event: Event): void {
    this.setNcNetworkConfig(this.ncNetworkConfig);
  }

  inputPortNumber(event: Event): void {
    this.setNcNetworkConfig(this.ncNetworkConfig);
  }

  // 選択したネットワーク設定をセットする
  selectNcNetwork(item, option): void {
    if (item.type === 'NCネットワーク設定値が') {
      this.ncNetworkConfig.IsExistConfig = option.value;
      if (option.value === false) {
        this.initializeConfig();
      }
    } else if (item.type === 'IPアドレス') {
      this.ncNetworkConfig.IPAddress = option.value;
      if (option.text === '手入力') {
        this.ipAddressInputFlag = true;
      } else {
        this.ipAddressInputFlag = false;
      }
    } else if (item.type === 'サブネットマスク') {
      this.ncNetworkConfig.SubnetMask = option.value;
      if (option.text === '手入力') {
        this.subnetMaskInputFlag = true;
      } else {
        this.subnetMaskInputFlag = false;
      }
    } else if (item.type === 'ポート番号') {
      this.ncNetworkConfig.PortNumber = option.value;
      if (option.text === '手入力') {
        this.portNumberInputFlag = true;
      } else {
        this.portNumberInputFlag = false;
      }
    } else {
    }
    this.setNcNetworkConfig(this.ncNetworkConfig);
  }

  // NC設定方法をダイアログで表示する
  openDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(NcNetconfigPhotoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }
}
