// ---------------------------------------------------------------------
// <copyright file="app-routing.module.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AnomalyResultComponent } from './components/anomaly-result/anomaly-result.component';
import { HomeComponent } from './components/home/home.component';
import { InspectProcessComponent } from './components/inspect-process/inspect-process.component';
import { LoginComponent } from './components/login/login.component';
import { MachineInfoComponent } from './components/machine-info/machine-info.component';
import { MachineListComponent } from './components/machine-list/machine-list.component';
import { MachineRegisterComponent } from './components/machine-register/machine-register.component';
import { NcNetWorkComponent } from './components/nc-network/nc-network.component';
import { PcNcConnectionComponent } from './components/pc-nc-connection/pc-nc-connection.component';
import { PreparetionComponent } from './components/preparetion/preparetion.component';
import { QrcodeInputComponent } from './components/qrcode-input/qrcode-input.component';
import { LoginGuard } from './guards/login.guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, canActivate: [LoginGuard] },
  {
    path: '',
    children: [
      { path: 'step0', component: PreparetionComponent },
      { path: 'step1', component: NcNetWorkComponent },
      { path: 'step2', component: QrcodeInputComponent },
      { path: 'step3', component: MachineRegisterComponent },
      { path: 'step4', component: PcNcConnectionComponent },
      { path: 'step5', component: AnomalyResultComponent },
    ],
    component: InspectProcessComponent,
    canActivate: [LoginGuard],
  },
  { path: 'list', component: MachineListComponent, canActivate: [LoginGuard] },
  {
    path: 'info/:id',
    component: MachineInfoComponent,
    canActivate: [LoginGuard],
  },
  { path: 'login', component: LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
