<body>
  <!-- Step4_1を表示、Stepが進めば#configureContentへ進む -->
  <ng-container *ngIf="step === 'Step4_1'; else configureContent">
    <!-- <app-lan-nc-connecting-photo></app-lan-nc-connecting-photo> -->
    <div class="body">
      <div [class]="stylePrePageing(nowPage)" (click)="previousPage(nowPage)">
        ◁
      </div>
      <div>
        <img class="img" [src]="imagePath[nowPage]">
        <div class="text-position">
          <div class="textbox" [innerHTML]="explanations[nowPage]"></div>
        </div>
      </div>
      <div [class]="styleNextPageing(nowPage)" (click)="nextPage(nowPage)">
        ▷
      </div>
    </div>
  </ng-container>
  <!-- Step4_2もしくはStep4_3のとき表示、Stepが進めば#connectContentへ進む -->
  <ng-template #configureContent>
    <ng-container *ngIf="step === 'Step4_2' || step === 'Step4_3' ; else connectContent">
      <img [src]="configureImgPath" class="image">
      <div class="center">{{explanation}}</div>
      <ng-container *ngIf="isFailure">
        <div class="retry-button">
          <button (click)="retryConfigure()">
            リトライ
          </button>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>
  <!-- Step4_4もしくはStep4_5のとき -->
  <ng-template #connectContent>
    <img [src]="connectImgPath" class="image">
    <div class="center">{{explanation}}</div>
  </ng-template>
</body>