<div class="upper">
  <!-- machineIDを表示 -->
  <div class="title">
    {{machineID}}
  </div>

  <div class="topbar-box">
    <!-- anomaly-iconコンポーネントを表示 -->
    <app-anomaly-icon class="anomaly-icon" [selectedAsset]="selectedAsset" [mode]="'info'" [assets]="assets"
      (event)="setAsset($event)"></app-anomaly-icon>

    <!-- TestProcessBoxを表示 -->
    <div class="testprocess-box">
      <ng-container *ngFor="let item of machine.TestProcessList | keyvalue">
        <div class="one-testprocess-box" [class]="selectedCSS(item.key)">
          <button [class]="selectedCSS(item.key)" (click)="setTestProcess(item.key, item.value)">{{item.key}}</button>
          <div class="approver">{{item.value.Approver | usernameFirst}}</div>
          <div *ngIf="item.value.Approval; else noApproval">
            <div class="approval" (click)="unCheckApproval(item.key)">☑︎</div>
          </div>
          <ng-template #noApproval>
            <div class="approval" (click)="checkApproval(item.key)">□</div>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- 日付と検査担当者を表示 -->
  <div class="user-info">
    <div>
      データ取得日:{{date | date:'y/M/d EEE, HH:mm'}}
    </div>
    <div>
      検査担当者:{{getOperator() | usernameFirst}}
    </div>
  </div>
</div>

<!-- 検査結果画像を表示 -->

<body>
  <div [class]="stylePrePageing(selectedAsset)" (click)="previousAsset(selectedAsset)">
    ◁
  </div>
  <div class="two-images">
    <img src={{anomalyImage[selectedAsset]}}>
    <img src={{preproImage[selectedAsset]}}>
  </div>
  <div [class]="styleNextPageing(selectedAsset)" (click)="nextAsset(selectedAsset)">
    ▷
  </div>
</body>

<!-- コメント窓を表示 -->
<app-comment-window [machineID]="machineID" [testProcess]="selectedProcess"></app-comment-window>

<!-- 診断結果一覧ボタンを表示 -->
<app-result-button [mode]="'info'" (event)="clickList($event)"></app-result-button>