// ---------------------------------------------------------------------
// <copyright file="machine-list.component.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { AssetScore } from 'src/app/models/asset-score';
import { AssetStatus } from 'src/app/models/asset-status';
import {
  AllTestProcess,
  Machine,
  MachineList,
  TestProcessHolder,
  TitleNames,
} from 'src/app/models/machinelist';
import { HttpService } from 'src/app/services/http.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * 機械の診断結果一覧画面を表示するコンポーネント
 */
@Component({
  selector: 'app-machine-list',
  templateUrl: './machine-list.component.html',
  styleUrls: ['./machine-list.component.css'],
})
export class MachineListComponent implements OnInit {
  public machineList: Machine[];
  public pageInitialMachineIDList: string[];
  public displayMachineList: Machine[];
  public selectedAsset: string;
  public titlenames = TitleNames;
  public allTestProcess = AllTestProcess;
  public assets: AssetScore = {};
  // public pageIndex: number = 0;
  public pageNumberList: number[] = [];
  private unsubscribe$ = new Subject();
  public pageTotalNumber: number;
  public currentPageIndex: number = 0;
  public loading: boolean = false;

  public limit: number = 250; // 1ページで取得、表示するmachineの(最大)数。

  constructor(private router: Router, private http: HttpService) {}

  ngOnInit(): void {
    this.loading = true;

    // リロード時のsessionStorageの削除用
    window.addEventListener('beforeunload', function (event) {
      sessionStorage.removeItem('pageInitList');
      sessionStorage.removeItem('currentPageIndex');
    });

    // localStorageからページ先頭リストと今のページを取り出す
    this.pageInitialMachineIDList = JSON.parse(
      sessionStorage.getItem('pageInitList')
    );
    this.currentPageIndex = parseInt(
      sessionStorage.getItem('currentPageIndex')
    );

    // ListとIndex両方あったらそれを使う
    if (
      this.pageInitialMachineIDList &&
      this.pageInitialMachineIDList.length &&
      !isNaN(this.currentPageIndex)
    ) {
      this.pageTotalNumber = this.pageInitialMachineIDList.length;
      this.pageNumberList = this.generatePageNumberList(this.pageTotalNumber);
      this.clickPage(this.currentPageIndex);
    } else {
      // index初期化
      this.currentPageIndex = 0;
      ////////// WEB API //////////
      this.http
        .httpGetRequest<any>(`/resultkeylistget?limit=${this.limit}`)
        .pipe(takeUntil(this.unsubscribe$))
        // APIの応答を受け取る
        .subscribe(
          (response) => {
            // 応答からpageInitialMachineIDを抽出
            this.pageInitialMachineIDList = response.LastEvaluatedKeyList;
            // localStorageに保存
            sessionStorage.setItem(
              'pageInitList',
              JSON.stringify(this.pageInitialMachineIDList)
            );
            sessionStorage.setItem(
              'currentPageIndex',
              this.currentPageIndex.toString()
            );

            // console.log(this.pageInitialMachineIDList);
            // pageInitialMachineIDを適切な変数に格納
            this.pageTotalNumber = this.pageInitialMachineIDList.length;
            this.pageNumberList = this.generatePageNumberList(
              this.pageTotalNumber
            );
            this.clickPage(this.currentPageIndex);
          },
          (error) => {
            console.error(error);
            this.loading = false;
          }
        );
    }
    /////////////////////////////
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    // ここでlocalStorageを初期化すると詳細画面から戻れなくなる
    // とはいえアプリ再起動時は初期化しないとまずいので、appcomponentに初期化させる
  }

  // pageInitialMachineIDに対応したMachineListを取ってくるメソッド
  private fetchMachineList(pageInitialMachineID: string): void {
    this.loading = true;
    this.http
      .httpGetRequest<any>(
        '/partialmachinelistget/' +
          pageInitialMachineID +
          `?limit=${this.limit}`
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          // console.log(response);
          // 値渡しするためにJSON変換を用いる
          let temp_response = JSON.parse(JSON.stringify(response));
          this.machineList = this.setMachineList(temp_response);
          this.loading = false;
        },
        (error) => {
          console.error(error);
          this.loading = false;
        }
      );
  }

  // pageNumberの配列を作るメソッド
  private generatePageNumberList(pageNumber: number): number[] {
    for (let i = 0; i < pageNumber; i++) {
      this.pageNumberList.push(i);
    }
    return this.pageNumberList;
  }

  public clickPage(pageIndex): void {
    this.currentPageIndex = pageIndex;
    // localStorageに保存
    sessionStorage.setItem(
      'currentPageIndex',
      this.currentPageIndex.toString()
    );

    const pageInitialMachineID =
      this.pageInitialMachineIDList[this.currentPageIndex];
    this.fetchMachineList(pageInitialMachineID);
  }

  public navigateToPreviousPage() {
    if (this.currentPageIndex > 0) {
      this.currentPageIndex--;
      this.clickPage(this.currentPageIndex);
    }
  }

  public navigateToNextPage() {
    if (this.currentPageIndex < this.pageNumberList.length - 1) {
      this.currentPageIndex++;
      this.clickPage(this.currentPageIndex);
    }
  }

  private setMachineList(response: any): Machine[] {
    let machineListTemp = new Array();
    for (var machine of response.MachineList) {
      let machineID = machine.MachineID;
      let commentNumber = machine.CommentNumber;
      let lastUpdateList = [];
      let latestLastUpdate: string;
      let testProcesses = {};

      for (const [phase, values] of Object.entries(machine)) {
        // Responseの中に検査工程があるか判定する
        if (this.allTestProcess.includes(phase)) {
          let temp = new TestProcessHolder(
            values['Operator'],
            values['Approver'],
            values['Approval'],
            values['LastUpdate'],
            values['Assets']
          );
          testProcesses[phase] = temp;
          testProcesses[phase] = this.setAssetScore(testProcesses[phase]);
          lastUpdateList.push(temp.LastUpdate);
        }
      }

      latestLastUpdate = lastUpdateList.sort(function (a, b) {
        return a < b ? 1 : -1;
      })[0];

      machineListTemp.push(
        new Machine(machineID, testProcesses, commentNumber, latestLastUpdate)
      );
    }
    // machineListをlatestLastUpdateで降順に並び替え
    machineListTemp = new MachineList(machineListTemp).sortDateDown();
    return machineListTemp;
  }

  /**
   *
   * @param testProcessHolder
   * @returns
   */
  private setAssetScore(
    testProcessHolder: TestProcessHolder
  ): TestProcessHolder {
    this.assets = new AssetScore(testProcessHolder.Assets);
    return new TestProcessHolder(
      testProcessHolder['Operator'],
      testProcessHolder['Approver'],
      testProcessHolder['Approval'],
      testProcessHolder['LastUpdate'],
      this.assets
    );
  }

  public clickMachine(machineID: string): void {
    this.setSelectesAsset('SP1', machineID);
  }

  public clickAsset(assetKey: string, machineID: string): void {
    this.setSelectesAsset(assetKey, machineID);
  }

  private setSelectesAsset(assetKey: string, machineID: string): void {
    this.selectedAsset = assetKey;
    this.router.navigate(['/info/' + machineID], {
      queryParams: { asset: assetKey },
    });
  }

  public clickTop(event: string): void {
    this.router.navigate(['/home']);
  }

  public getJudgement(machineID: string, testProcessName: string): boolean {
    // console.log('parameter');
    let machine = this.machineList.find(
      (machineList) => machineList.MachineID === machineID
    );
    // console.log(machine);
    for (const [phase, values] of Object.entries(machine.TestProcessList)) {
      if (phase === testProcessName) {
        let assets = machine.TestProcessList[phase].Assets;
        // 軸1つ1つのOKかNGの判定
        for (const [assetKey, assetValue] of Object.entries(assets)) {
          if (assets[assetKey].State !== AssetStatus.normal) {
            return false;
          }
        }
      }
    }
    return true;
  }

  public pageActive(index: number): string {
    const style: string =
      index === this.currentPageIndex ? 'active' : 'not-active';
    return style;
  }
}
