<ng-container [ngSwitch]="step">

  <body *ngSwitchCase="Step3_box.first">

    <body>
      <!-- machine-specコンポーネントからmachineSpecEventが渡される -->
      <app-machine-spec [machineID]="machineID" (machineSpecEvent)="setMachineSpec($event)"
        (assets)="setAssets($event)">
      </app-machine-spec>
    </body>
  </body>

  <body *ngSwitchCase="Step3_box.second">

    <body>
      <app-asset-select (apiassets)="setApiAssets($event)">
      </app-asset-select>
    </body>
  </body>

  <body *ngSwitchCase="Step3_box.third">

    <body>
      <app-configure-json></app-configure-json>
    </body>
  </body>
</ng-container>