// ---------------------------------------------------------------------
// <copyright file="asset.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

/**
 * 診断結果画像を出力するためのモデル
 */
export const DefaultImage = encodeURI('/assets/komainu_icon/写真アイコン9.png');

export class Asset {
  AssetName: string;
  AxisNum: number;
  Model: string;
  DataType: string;
  PreproSrc: string;
  ResultSrc: string;
  Score: number;
  TimeStamp: string;

  constructor(asset: any) {
    this.AssetName = asset.AssetName;
    this.AxisNum = asset.AxisNum;
    this.Model = asset.Model;
    this.DataType = asset.DataType;
    this.PreproSrc = DefaultImage;
    this.ResultSrc = DefaultImage;
    this.Score = null;
    this.TimeStamp = null;
  }
}

export enum AssetOrder {
  SP,
  X1,
  Y1,
  Z1,
  A1,
  B1,
  C1,
}
