// ---------------------------------------------------------------------
// <copyright file="operation-phase.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

/**
 * 運用段階の種類、背景色の保存場所
 */
export enum operationType {
  Init = '運用段階',
  DataCollecting = 'データ収集',
  Trial = '試運用',
  Released = '本運用',
}

export enum OperationImageFlag {
  Init,
  DataCollecting,
  Trial,
  Released,
}

export interface operationPhaseMap {
  [index: string]: { type: operationType; color: string };
}

export class OperationPhaseMap {
  public operationPhaseMap: operationPhaseMap;

  constructor() {
    this.operationPhaseMap = {
      Init: { type: operationType.Init, color: 'white' },
      DataCollecting: {
        type: operationType.DataCollecting,
        color: 'aliceblue',
      },
      Trial: { type: operationType.Trial, color: 'darkorange' },
      Released: { type: operationType.Released, color: 'deeppink' },
    };
  }
}
