// ---------------------------------------------------------------------
// <copyright file="anomaly-icon.component.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { AssetOrder } from 'src/app/models/asset';
import { AssetScore } from 'src/app/models/asset-score';
import { AssetStatus } from 'src/app/models/asset-status';

import {
    Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';

/**
 * MachineListComponentで、異常度スコアに合わせて色付きのアイコンを表示するコンポーネント
 */
@Component({
  selector: 'app-anomaly-icon',
  templateUrl: './anomaly-icon.component.html',
  styleUrls: ['./anomaly-icon.component.css'],
})
export class AnomalyIconComponent implements OnInit, OnChanges {
  @Input() mode: string;
  @Input() selectedAsset: string;
  @Input() assets: AssetScore;
  @Output() event: EventEmitter<string> = new EventEmitter();
  public assetList: string[] = [];
  public assetMap = {};

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.assets);
    for (const [assetKey, assetValue] of Object.entries(this.assets)) {
      const subAsset = assetKey.substring(0, 2);
      if (!this.assetList.includes(subAsset)) {
        this.assetList.push(subAsset);
        this.assetMap[subAsset] = {};
      }
      this.assetMap[subAsset][assetKey] = assetValue;
    }
    this.assetList.sort((a, b) => {
      return AssetOrder[a] - AssetOrder[b];
    });
  }

  styleAsset(assetKey: string, asset: any, mode: string): string {
    const length = Object.keys(asset).length;
    let style: string;
    if (length === 1) {
      if (mode === 'info') {
        style = 'box1-info';
      } else {
        style = 'box1-list';
      }
    } else {
      if (mode === 'info') {
        style = 'box2-info';
      } else {
        style = 'box2-list';
      }
    }
    return `${style} ${this.backgroundColor(assetKey)}`;
  }

  clickAsset(assetKey: string): void {
    this.selectedAsset = assetKey;
    this.event.emit(this.selectedAsset);
  }

  // 軸選択ボタンの色設定
  backgroundColor(asset: string): string {
    let color = '';
    if (asset === this.selectedAsset) {
      switch (this.assets[asset].State) {
        case AssetStatus.normal:
        default:
          color = 'selected-blue';
          break;
        case AssetStatus.warning:
          color = 'selected-yellow';
          break;
        case AssetStatus.critical:
          color = 'selected-red';
          break;
      }
    } else {
      switch (this.assets[asset].State) {
        case AssetStatus.normal:
        default:
          color = 'blue';
          break;
        case AssetStatus.warning:
          color = 'yellow';
          break;
        case AssetStatus.critical:
          color = 'red';
          break;
      }
    }
    return color;
  }
}
