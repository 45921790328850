<ng-container [ngSwitch]="step">

  <body *ngSwitchCase="Step5_box.first">
  </body>

  <body *ngSwitchCase="Step5_box.second">
    <div class="title">
      {{machineID}}
    </div>
    <div class="comment pre-wrap" [innerHTML]="comment"></div>
    <div class="assets">
      <div *ngFor="let asset of assets" class="asset-item">
        {{asset.AssetName}}
        <div class="image" [class.alert-image]="isAlartImage(asset.Score)">
          <img [src]="asset.ResultSrc" (click)="openDialog(asset.Name, asset.PreproSrc, asset.anomalyResult)">
        </div>
      </div>
    </div>
  </body>

  <body *ngSwitchCase="Step5_box.third">
    <div class="result">
      診断結果
    </div>
    <div class="assets">
      <div *ngFor="let asset of assets" class="asset-item">
        {{asset.AssetName}}
        <div class="image" [class.alert-image]="isAlartImage(asset.Score)">
          <img [src]="asset.ResultSrc" (click)="openDialog(asset.Name, asset.PreproSrc, asset.anomalyResult)">
        </div>
      </div>
    </div>
    <div class="textbox" [innerHtml]="alartText(assets)"></div>
  </body>

  <body *ngSwitchCase="Step5_box.forth">
    <div class="title">
      {{machineID}}
    </div>
    <div class="comment pre-wrap" [innerHTML]="comment"></div>
    <div class="assets">
      <div *ngFor="let asset of assets" class="asset-item">
        {{asset.AssetName}}
        <div class="image" [class.alert-image]="isAlartImage(asset.Score)">
          <img [src]="asset.ResultSrc" (click)="openDialog(asset.Name, asset.PreproSrc, asset.anomalyResult)">
        </div>
      </div>
    </div>
    <form [formGroup]="reactiveForm">
      <div class="container">
        <div>
          <div>コメントを送信する</div>
          <div>工程:{{testProcess}} 名前:{{username | usernameFirst}}</div>
          <input class="input-text" type="text" id="message" formControlName="message">
          <button (click)="sendComment()" [disabled]="reactiveForm.invalid">送信</button><br>
          <div>{{waitingMessage}}</div>
        </div>
      </div>
    </form>
  </body>
</ng-container>