import Amplify, { Auth } from 'aws-amplify';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { Injectable } from '@angular/core';

const CognitoIDPool = 'CognitoIdentityServiceProvider';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public loggedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public webStorage;
  private cognitoPool = environment.userPoolWebClientId;
  get username(): string {
    const uuid = this.webStorage.getItem(
      `${CognitoIDPool}.${this.cognitoPool}.LastAuthUser`
    );
    const attributes: any = this.webStorage.getItem(
      `${CognitoIDPool}.${this.cognitoPool}.${uuid}.userData`
    );
    const user = JSON.parse(attributes).UserAttributes?.find(
      (attribute) => attribute.Name === 'name'
    );
    return user?.Value;
  }

  get email(): string {
    const uuid = this.webStorage.getItem(
      `${CognitoIDPool}.${this.cognitoPool}.LastAuthUser`
    );
    const attributes: any = this.webStorage.getItem(
      `${CognitoIDPool}.${this.cognitoPool}.${uuid}.userData`
    );
    const email = JSON.parse(attributes).UserAttributes?.find(
      (attribute) => attribute.Name === 'email'
    );
    return email?.Value;
  }

  constructor() {
    const retainState = localStorage.getItem('retainState') === 'true';
    this.webStorage = retainState ? localStorage : sessionStorage;
    Amplify.configure({
      Auth: {
        region: environment.region,
        userPoolId: environment.userPoolId,
        userPoolWebClientId: environment.userPoolWebClientId,
        authenticationFlowType: 'USER_SRP_AUTH',
        storage: this.webStorage,
      },
    });
  }

  setAmplifyConfigure(retainState: boolean): void {
    this.webStorage = retainState ? localStorage : sessionStorage;
    Amplify.configure({
      Auth: {
        storage: this.webStorage,
      },
    });
  }

  // Send confirmation code to user's email
  forgetPassword(username: string): Observable<any> {
    return from(Auth.forgotPassword(username));
  }

  // Collect confirmation code and new password, then
  forgetPasswordSubmit(
    username: string,
    code: string,
    newPassword: string
  ): Observable<any> {
    return from(Auth.forgotPasswordSubmit(username, code, newPassword));
  }

  // 最初のログインのパスワードとユーザー情報の変更
  completeNewPassword(user, password, attributes): Observable<any> {
    return from(Auth.completeNewPassword(user, password, attributes));
  }

  // 認証コードの確認
  verifyUserAttributeSubmit(user, verificationCode): Observable<any> {
    return from(
      Auth.verifyUserAttributeSubmit(user, 'email', verificationCode)
    );
  }

  // 認証コードの再送信
  verifyUserAttribute(user): void {
    from(Auth.verifyUserAttribute(user, 'email'));
  }

  // ログイン
  signIn(email, password): Observable<any> {
    return from(Auth.signIn(email, password)).pipe(
      tap((result) => {
        this.loggedIn.next(true);
      })
    );
  }

  // サインアップ
  signUp(email: string, passWord: string, fullname: string): Observable<any> {
    const date = new Date();
    const updateAt = Math.floor(date.getTime() / 1000).toString();
    return from(
      Auth.signUp({
        username: email,
        password: passWord,
        attributes: {
          name: fullname,
          email: email,
          updated_at: updateAt,
        },
      })
    );
  }

  /** 検証 */
  confirmSignUp(email, code): Observable<any> {
    return from(Auth.confirmSignUp(email, code));
  }

  // ログイン状態の取得
  isAuthenticated(): Observable<boolean> {
    return from(Auth.currentAuthenticatedUser()).pipe(
      map((result) => {
        // if (result.challengeName === undefined && 'email_verified' in result.attributes){
        this.loggedIn.next(true);
        return true;
      }),
      catchError((error) => {
        this.loggedIn.next(false);
        return of(false);
      })
    );
  }

  // ログアウト
  signOut(): void {
    from(Auth.signOut()).subscribe(
      (result) => {
        this.loggedIn.next(false);
      },
      (error) => console.log(error)
    );
  }
}
