// ---------------------------------------------------------------------
// <copyright file="nc-netconfig-photo.component.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Explanations, ImagePath } from 'src/app/models/nc-netconfig-photo';

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-nc-netconfig-photo',
  templateUrl: './nc-netconfig-photo.component.html',
  styleUrls: ['./nc-netconfig-photo.component.css'],
})
export class NcNetconfigPhotoComponent implements OnInit {
  public nowPage: string;
  public imagePath: typeof ImagePath = ImagePath;
  public explanations: typeof Explanations = Explanations;
  public readonly NCState: string[] = [
    'NC1',
    'NC2',
    'NC3',
    'NC4',
    'NC5',
    'NC6',
    'NC7',
  ];

  constructor(public dialogRef: MatDialogRef<NcNetconfigPhotoComponent>) {}

  ngOnInit(): void {
    this.nowPage = this.NCState[0];
  }

  previousPage(nowPage: string): void {
    const index = this.NCState.indexOf(nowPage);
    if (index > 0) {
      this.nowPage = this.NCState[index - 1];
    }
  }

  nextPage(nowPage: string): void {
    const index = this.NCState.indexOf(nowPage);
    if (index < 6) {
      this.nowPage = this.NCState[index + 1];
    }
  }

  stylePrePageing(nowPage: string): string {
    if (nowPage === this.NCState[0]) {
      return 'hidden-page-button';
    }
    return 'show-page-button';
  }

  styleNextPageing(nowPage: string): string {
    if (nowPage === this.NCState[6]) {
      return 'hidden-page-button';
    }
    return 'show-page-button';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
