<body>
  <!-- ダイアログボタンを表示 -->
  <br>
  <button class="dialog" (click)="openDialog()">NC設定方法</button>

  <!-- 手入力かデフォルト設定かを切り替える -->
  <div class="type">
    {{isExistConfig.type}}
  </div>

  <div class="input-box">
    <button id="isExistConfig" *ngFor="let option of isExistConfig.values"
      [class]="selectedCSS(ncNetworkConfig.IsExistConfig, option.value)"
      (click)="selectNcNetwork(isExistConfig, option)">{{option.text}}
    </button>
  </div>

  <!-- 手入力の場合 -->
  <div *ngIf="ncNetworkConfig.IsExistConfig">
    <div class="type">
      {{ipAddressSelections.type}}
    </div>
    <div class="input-box">
      <button id="ipAddress" *ngFor="let option of ipAddressSelections.values"
        [class]="selectedCSS(ncNetworkConfig.IPAddress, option.value)"
        (click)="selectNcNetwork(ipAddressSelections, option)">{{option.text}}
      </button>
    </div>
    <div class="input-box" *ngIf="ipAddressInputFlag">
      <input type="string" [(ngModel)]="ncNetworkConfig.IPAddress" (change)="inputIPAddress($event)">
    </div>

    <div class="type">
      {{subnetMaskSelections.type}}
    </div>
    <div class="input-box">
      <button id="subnetMask" *ngFor="let option of subnetMaskSelections.values"
        [class]="selectedCSS(ncNetworkConfig.SubnetMask, option.value)"
        (click)="selectNcNetwork(subnetMaskSelections, option)">{{option.text}}
      </button>
    </div>
    <div class="input-box" *ngIf="subnetMaskInputFlag">
      <input type="string" [(ngModel)]="ncNetworkConfig.SubnetMask" (change)="inputSubnetMask($event)">
    </div>

    <div class="type">
      {{portNumberSelections.type}}
    </div>
    <div class="input-box">
      <button id="portNumber" *ngFor="let option of portNumberSelections.values"
        [class]="selectedCSS(ncNetworkConfig.PortNumber, option.value)"
        (click)="selectNcNetwork(portNumberSelections, option)">{{option.text}}
      </button>
    </div>
    <div class="input-box" *ngIf="portNumberInputFlag">
      <input type="string" [(ngModel)]="ncNetworkConfig.PortNumber" (change)="inputPortNumber($event)">
    </div>

  </div>

  <!-- デフォルト設定の場合 -->
  <div *ngIf="!ncNetworkConfig.IsExistConfig">
    <div class="message">
      IPアドレスを<b>192.168.20.10</b> <br>サブネットマスクを<b>255.255.255.0</b> <br>ポート番号を<b>8193</b>に設定して <br>NCを再起動してください。
    </div>

  </div>
</body>