// ---------------------------------------------------------------------
// <copyright file="comment-window.component.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Comment, CommentList } from 'src/app/models/comment-window';
import { AuthService } from 'src/app/services/auth.service';
import { HttpService } from 'src/app/services/http.service';

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-comment-window',
  templateUrl: './comment-window.component.html',
  styleUrls: ['./comment-window.component.css'],
})
export class CommentWindowComponent implements OnInit {
  @Input() machineID: string;
  @Input() testProcess: string;

  public username: string;
  public reactiveForm: FormGroup;
  public commentList: Comment[];
  public commentRestLength: string;
  public commentLatest: Comment;
  public isCommentExist: boolean;
  public send_comment: string;
  public future_comment: string;
  public isCommentWindowShow: boolean;
  public waitingMessage: string;

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private http: HttpService
  ) {
    this.username = this.auth.username;
    this.reactiveForm = this.formBuilder.group({
      message: [''],
    });
  }
  ngOnInit(): void {
    this.isCommentWindowShow = false;
    this.waitingMessage = '';
    this.isCommentExist = false;

    ////////// WEB API //////////
    this.http
      .httpGetRequest<any>(`/commentlistget/${this.machineID}`)
      .subscribe((response) => {
        console.log(response);
        this.commentList = new CommentList(response.CommentList).sortDateDown();
        this.commentRestLength = (this.commentList.length - 1).toString();
        // コメントが0でない場合
        if (this.commentRestLength !== '-1') {
          this.commentLatest = this.commentList.slice(-1)[0];
          this.isCommentExist = true;
        }
      });
    /////////////////////////////;
  }

  sendComment(): void {
    // Formから送信するメッセージを取得
    this.waitingMessage = 'コメントを送信しています...';
    this.send_comment = this.reactiveForm.controls['message'].value;

    // WEB API POST用のbodyを作成する
    let body = {
      machineID: this.machineID,
      user: this.username,
      comment: this.send_comment,
      testProcess: this.testProcess,
    };

    ////////// WEB API //////////
    this.http.httpPostRequest<any>('/commentput', body).subscribe(
      (response) => {
        console.log(response);
        console.log(`WebAPI /commentputapi Completed.}`);

        console.log(this.send_comment);

        // コメント送信3秒後に画面更新
        this.waitingMessage = 'コメントを送信しました。';
        const delay_observable = of('').pipe(delay(3000));
        delay_observable.subscribe((s) => {
          this.ngOnInit();
        });
      },
      () => {}
    );
    /////////////////////////////
  }

  openWindow(): void {
    this.isCommentWindowShow = true;
  }

  closeWindow(): void {
    this.isCommentWindowShow = false;
  }
}
