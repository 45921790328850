// ---------------------------------------------------------------------
// <copyright file="compact-pc.service.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { BehaviorSubject, Observable } from 'rxjs';

import { Injectable } from '@angular/core';

/**
 * CompactPCStatusの状態管理を行うサービス、3秒に一回更新するため、他のパラメータと分けてサービス化している
 *
 */
@Injectable({
  providedIn: 'root',
})
export class CompactPcService {
  private onlineStatus: BehaviorSubject<boolean>;
  private jobStatus: BehaviorSubject<string>;
  private ncConnectivity: BehaviorSubject<boolean>;
  private dataCollection: BehaviorSubject<boolean>;

  constructor() {
    this.onlineStatus = new BehaviorSubject(false);
    this.jobStatus = new BehaviorSubject('');
    this.ncConnectivity = new BehaviorSubject(false);
    this.dataCollection = new BehaviorSubject(false);
  }

  onlineStatusNext(onlineStatus: boolean): void {
    this.onlineStatus.next(onlineStatus);
  }

  jobStatusNext(jobStatus: string): void {
    this.jobStatus.next(jobStatus);
  }

  ncConnectivityNext(ncConnectivity: boolean): void {
    this.ncConnectivity.next(ncConnectivity);
  }

  dataCollectionNext(dataCollection: boolean): void {
    this.dataCollection.next(dataCollection);
  }

  observeOnlineStatus(): Observable<boolean> {
    return this.onlineStatus.asObservable();
  }

  observeJobStatus(): Observable<string> {
    return this.jobStatus.asObservable();
  }

  observeNCConnectivity(): Observable<boolean> {
    return this.ncConnectivity.asObservable();
  }

  observeDataConnection(): Observable<boolean> {
    return this.dataCollection.asObservable();
  }
}
