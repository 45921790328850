// ---------------------------------------------------------------------
// <copyright file="commentzero.pipe.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commentzero',
})
export class CommentzeroPipe implements PipeTransform {
  public commentNumberStr: string;
  transform(commentNumber: number): string {
    if (commentNumber == 0) {
      this.commentNumberStr = '-';
    } else {
      this.commentNumberStr = commentNumber.toString();
    }
    return this.commentNumberStr;
  }
}
