// ---------------------------------------------------------------------
// <copyright file="nc-network.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

/**
 * ネットワーク設定をまとめたmodel
 */
export class NcNetworkConfig {
  IsExistConfig: boolean;
  IPAddress: string;
  SubnetMask: string;
  PortNumber: string;

  constructor(ncNetworkConfig?: any) {
    if (ncNetworkConfig) {
      this.IsExistConfig = false;
      this.IPAddress = ncNetworkConfig.IPAddress;
      this.SubnetMask = ncNetworkConfig.SubnetMask;
      this.PortNumber = ncNetworkConfig.PortNumber;

      console.log(`IsExistConfig: ${this.IsExistConfig}`);
      console.log(`IPAddress: ${this.IPAddress}`);
      console.log(`SubnetMask: ${this.SubnetMask}`);
      console.log(`PortNum: ${this.PortNumber}`);
    }
  }
  isUndefined(): boolean {
    if (
      !this.IPAddress ||
      this.IPAddress === undefined ||
      !this.SubnetMask ||
      this.SubnetMask === undefined ||
      !this.PortNumber ||
      this.PortNumber === undefined
    ) {
      return true;
    }
    return false;
  }
}
