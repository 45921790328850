// ---------------------------------------------------------------------
// <copyright file="app-info.component.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { AuthService } from 'src/app/services/auth.service';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

export enum Status {
  Logout,
  NowLogin,
  Login,
  SignUp,
  ConfirmSignUp,
}
/**
 * ログイン画面のコンポーネント
 * */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  public readonly dogPath = encodeURI('assets/komainu_icon/柴犬のイラスト.png');
  public status: typeof Status = Status;
  public state: Status = Status.Logout;
  set retainState(state: boolean) {
    localStorage.setItem('retainState', state.toString());
  }
  get retainState(): boolean {
    return localStorage.getItem('retainState') === 'true';
  }
  public username: string;
  public firstName: string;
  public lastName: string;
  public password: string; // form使うかprivateにする、Debug中のみ
  public verifyCode: string;
  public errorMessage: string;

  constructor(private router: Router, private auth: AuthService) {}

  ngOnInit(): void {
    this.username = '';
    this.password = '';
  }

  clickLogin(): void {
    console.log(this.state);
    this.state = Status.NowLogin;
    console.log(this.state);
    console.log(this.retainState);
    this.auth.setAmplifyConfigure(this.retainState);
    this.auth.signIn(this.username, this.password).subscribe(
      (result) => {
        this.state = Status.Login;
        console.log(result);
        this.router.navigate(['/home']);
      },
      (error) => {
        this.state = Status.Logout;
        switch (error.code) {
          case 'AuthError':
            this.errorMessage = 'メールアドレスを入力してください。';
            console.log(error);
            break;
          case 'UserNotConfirmedException':
            // ユーザのステータスが UNCONFIRMED の場合に起こる。
            // SignUp用のコードを再送し、ステータスを CONFIRMED にする必要がある。
            // 検証コードの再送は １．３節の ResendConfirmationCode() を参照。
            this.errorMessage = 'メールアドレスの再検証が必要です。';
            console.log(error);
            break;
          case 'PasswordResetRequiredException':
            // Cognito コンソールでパスワードをリセット（ユーザープールにユーザをインポートする場合も含む）した場合に起こる。
            // パスワードをリセットする必要がある。
            // パスワードのリセットは 3.1節の SendForgotPasswordCode() 参照。
            this.errorMessage = 'パスワードのリセットが必要です。';
            console.log(error);
            break;
          case 'NotAuthorizedException':
            // 誤ったパスワードを入力した場合に起こる。
            // 注) パスワードを間違え続けた場合にも起こり、 error.message が 'Password attempts exceeded' になる。
            // （エラーコードとして LimitExceededException が返ってくると思ったらそうではなかった）
            this.errorMessage = 'パスワードが間違っています。';
            console.log(error);
            break;
          case 'UserNotFoundException':
            // PASSWORD_VERIFIER は通るものの username が Cognito ユーザープールに存在しない場合に起こる。
            this.errorMessage = 'メールアドレスが存在しません。';
            console.log(error);
            break;
          case 'InvalidParameterException':
            // 入力された username や password が Cognito 側で正しくパースできないとき（バリデーションエラー）に起こる。
            // 注) 2019/04/24 現在、Cognito コンソールでパスワードをリセットした場合は 'PasswordResetRequiredException' ではなくこのエラーコードが返される。
            this.errorMessage =
              'メールアドレスまたはパスワードの形式が誤っています。';
            console.log(error);
            break;
          default:
            // その他のエラー
            this.errorMessage = 'Unknown Error Occured!';
            console.log(error);
            break;
        }
      }
    );
  }

  clickSignUp(): void {
    const name = `${this.lastName}_${this.firstName}`;
    console.log(name);
    this.auth.signUp(this.username, this.password, name).subscribe(
      (result) => {
        this.errorMessage = undefined;
        this.state = Status.ConfirmSignUp;
        console.log(result);
      },
      (error) => {
        switch (error.code) {
          case 'UsernameExistsException':
            // ユーザープール内に既に同じ username が存在する場合に起こる。
            this.errorMessage = 'このメールアドレスは既に登録されています。';
            console.log(error);
            break;
          case 'InvalidPasswordException':
            // ユーザープールのポリシーで設定したパスワードの強度を満たさない場合に起こる。
            this.errorMessage =
              'パスワードが要件を満たしていません。(大文字、小文字、記号、数字を含む6文字から20文字)';
            console.log(error);
            break;
          case 'InvalidParameterException':
            // 必要な属性が足りない場合や、入力された各項目が Cognito 側で正しくパースできない場合（バリデーションエラー）に起こる。
            // password が6文字未満の場合はバリデーションエラーでこちらのエラーコードが返ってくる。
            this.errorMessage = '正しく入力されていない項目があります。';
            console.log(error);
            break;
          default:
            // その他のエラー
            this.errorMessage = 'Unknown Error Occured!';
            console.log(error);
            break;
        }
      }
    );
  }

  clickConfirmSignUp(): void {
    this.auth.confirmSignUp(this.username, this.verifyCode).subscribe(
      (result) => {
        this.errorMessage = undefined;
        this.state = Status.Logout;
        console.log(result);
      },
      (error) => {
        switch (error.code) {
          case 'CodeMismatchException':
            // 無効なコードが入力された場合に起こる。
            this.errorMessage = '無効なコードです。';
            console.log(error);
            break;
          case 'LimitExceededException':
            // コードを間違え続けた場合に起こる。
            this.errorMessage =
              '一定回数以上の入力の誤りを検知したため、確認コードが無効になりました。';
            console.log(error);
            break;
          case 'ExpiredCodeException':
            // コードが期限切れ（24時間をオーバー）した場合に起こる。
            // 注) username が存在しない・無効化されている場合にも起こる。
            this.errorMessage = 'コードの有効期限が切れています。';
            console.log(error);
            break;
          case 'NotAuthorizedException':
            // 既にステータスが CONFIRMED になっている場合に起こる。
            this.errorMessage = 'このコードは既に有効です。';
            console.log(error);
            break;
          case 'CodeDeliveryFailureException':
            // 検証コードの送信に失敗した場合に起こる。
            this.errorMessage = '検証コードが送信できませんでした。';
            console.log(error);
            break;
          default:
            // その他のエラー
            this.errorMessage = 'Unknown Error Occured!';
            console.log(error);
            break;
        }
      }
    );
  }

  clickStatusSignIn(): void {
    this.username = undefined;
    this.password = undefined;
    this.errorMessage = undefined;
    this.state = Status.Logout;
  }

  clickStatusSignUp(): void {
    this.username = undefined;
    this.password = undefined;
    this.errorMessage = undefined;
    this.state = Status.SignUp;
  }
}
