// ---------------------------------------------------------------------
// <copyright file="app.module.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

/**
 * アプリ全体で読み込むコンポーネント、サービスを記述する、サービスのDI仕様(newするか単一の値を使用するか)はここで指定できる
 */
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button'; // 追加
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AssetSelectComponent } from './component-parts/body/asset-select/asset-select.component';
import { ConfigureJsonComponent } from './component-parts/body/configure-json/configure-json.component';
import { LanNcConnectingPhotoComponent } from './component-parts/body/lan-nc-connecting-photo/lan-nc-connecting-photo.component';
import { MachineSpecComponent } from './component-parts/body/machine-spec/machine-spec.component';
import { NcNetconfigPhotoComponent } from './component-parts/body/nc-netconfig-photo/nc-netconfig-photo.component';
import { QrcodeComponent } from './component-parts/body/qrcode/qrcode.component';
import { ResultButtonComponent } from './component-parts/footers/result-button/result-button.component';
import { UnderButtonComponent } from './component-parts/footers/under-button/under-button.component';
import { HeaderInfoComponent } from './component-parts/headers/header-info/header-info.component';
import { HeaderTitleComponent } from './component-parts/headers/header-title/header-title.component';
import { HeaderUserComponent } from './component-parts/headers/header-user/header-user.component';
import { HeaderComponent } from './component-parts/headers/header/header.component';
import { AnomalyIconComponent } from './component-parts/others/anomaly-icon/anomaly-icon.component';
import { AppInfoComponent } from './component-parts/others/app-info/app-info.component';
import { CommentWindowComponent } from './component-parts/others/comment-window/comment-window.component';
import { CommentComponent } from './component-parts/others/comment/comment.component';
import { DialogOverviewComponent } from './component-parts/others/dialog-overview/dialog-overview.component';
import { SearchMachineComponent } from './component-parts/others/search-machine/search-machine.component';
import { AnomalyResultComponent } from './components/anomaly-result/anomaly-result.component';
import { HomeComponent } from './components/home/home.component';
import { InspectProcessComponent } from './components/inspect-process/inspect-process.component';
import { LoginComponent } from './components/login/login.component';
import { MachineInfoComponent } from './components/machine-info/machine-info.component';
import { MachineListComponent } from './components/machine-list/machine-list.component';
import { MachineRegisterComponent } from './components/machine-register/machine-register.component';
import { NcNetWorkComponent } from './components/nc-network/nc-network.component';
import { PcNcConnectionComponent } from './components/pc-nc-connection/pc-nc-connection.component';
import { PreparetionComponent } from './components/preparetion/preparetion.component';
import { QrcodeInputComponent } from './components/qrcode-input/qrcode-input.component';
import { LoginGuard } from './guards/login.guard';
import { CommentzeroPipe } from './pipes/commentzero.pipe';
import { KomainuPipe } from './pipes/komainu.pipe';
import { UsernamePipe } from './pipes/username.pipe';
import { AuthService } from './services/auth.service';
import { ButtonService } from './services/button.service';
import { CompactPcService } from './services/compact-pc.service';
import { HttpService } from './services/http.service';
import { ProcessService } from './services/process.service';
import { UsernameFirstPipe } from './pipes/username-first.pipe';
import { ApprovalWindowComponent } from './component-parts/others/approval-window/approval-window.component';
import { QrTestprocessPipe } from './pipes/qr-testprocess.pipe';
import { DisplayLoadingComponent } from './component-parts/display-loading/display-loading.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    AppComponent,
    QrcodeComponent,
    LoginComponent,
    HomeComponent,
    HeaderComponent,
    CommentComponent,
    InspectProcessComponent,
    NcNetWorkComponent,
    MachineRegisterComponent,
    PcNcConnectionComponent,
    UnderButtonComponent,
    HeaderUserComponent,
    HeaderTitleComponent,
    HeaderInfoComponent,
    MachineSpecComponent,
    AnomalyResultComponent,
    MachineListComponent,
    MachineInfoComponent,
    AnomalyIconComponent,
    SearchMachineComponent,
    ResultButtonComponent,
    KomainuPipe,
    DialogOverviewComponent,
    UsernamePipe,
    PreparetionComponent,
    AppInfoComponent,
    NcNetconfigPhotoComponent,
    AssetSelectComponent,
    LanNcConnectingPhotoComponent,
    ConfigureJsonComponent,
    QrcodeInputComponent,
    CommentWindowComponent,
    CommentzeroPipe,
    UsernameFirstPipe,
    ApprovalWindowComponent,
    QrTestprocessPipe,
    DisplayLoadingComponent,
  ],
  imports: [
    AppRoutingModule,
    ZXingScannerModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    NgbModule,
    HttpClientModule,
    HttpClientJsonpModule,
    RouterModule,
    MatProgressSpinnerModule
  ],
  providers: [
    LoginGuard,
    ButtonService,
    HttpService,
    CompactPcService,
    AuthService,
    ProcessService,
  ],
  entryComponents: [DialogOverviewComponent],
  bootstrap: [AppComponent],
})
export class AppModule { }
