// ---------------------------------------------------------------------
// <copyright file="pc-nc-connection.component.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Subscription } from 'rxjs';
import { Explanations, ImagePath } from 'src/app/models/nc-netconfig-photo';
import { ButtonService } from 'src/app/services/button.service';
import { CompactPcService } from 'src/app/services/compact-pc.service';
import { HttpService } from 'src/app/services/http.service';
import { ProcessService } from 'src/app/services/process.service';

import { Component, OnDestroy, OnInit } from '@angular/core';

/**
 * CompactPCとNCの接続環境を確認、CompactPCのConfigurationを行うコンポーネント
 */

@Component({
  selector: 'app-pc-nc-connection',
  templateUrl: './pc-nc-connection.component.html',
  styleUrls: ['./pc-nc-connection.component.css'],
})
export class PcNcConnectionComponent implements OnInit, OnDestroy {
  public nowPage: string;
  public imagePath: typeof ImagePath = ImagePath;
  public configureImgPath: string;
  public connectImgPath: string;
  public explanations: typeof Explanations = Explanations;
  public caution: string;
  public readonly NCState: string[] = ['LAN1', 'LAN2', 'LAN3'];

  public onlineStatus: boolean;
  public step: string;
  public explanation: string;
  public isFailure: boolean;
  public timeoutId: null | ReturnType<typeof setTimeout> = null;

  private readonly configureWaitingPath = encodeURI(
    'assets/komainu_icon/configure_waiting_compactpc.png'
  );
  private readonly configureCompletedPath = encodeURI(
    'assets/komainu_icon/configure_completed_compactpc.png'
  );
  private readonly configuringPath = encodeURI(
    'assets/komainu_icon/configuring_compactpc.png'
  );
  private readonly configureFailedPath = encodeURI(
    'assets/komainu_icon/configure_failed_compactpc.png'
  );

  private readonly connectingPath = encodeURI(
    'assets/komainu_icon/nc_connecting.png'
  );
  private readonly connectedPath = encodeURI(
    'assets/komainu_icon/nc_connected.png'
  );

  private subscribeStep: Subscription;
  private subscribeButton: Subscription;
  private subscribeOnlineStatus: Subscription;
  private subscribeJobStatus: Subscription;
  private subscribeConectivity: Subscription;

  constructor(
    private processService: ProcessService,
    private button: ButtonService,
    private compactPC: CompactPcService,
    private http: HttpService
  ) {}

  ngOnInit(): void {
    this.nowPage = this.NCState[0];
    this.isFailure = false;
    this.explanation = '';
    this.configureImgPath = this.configureWaitingPath;
    const machineID = localStorage.getItem('MachineID');
    const compactPCID = localStorage.getItem('CompactPC');

    // stepをsubscribeしながら、状態遷移を行う
    this.subscribeStep = this.processService.observeStep().subscribe((step) => {
      this.step = step;

      // 1. CompactPCがオンライン状態の場合、OKボタンを表示する
      if (step === 'Step4_1') {
        this.button.disabledNext(true);
        this.subscribeOnlineStatus = this.compactPC
          .observeOnlineStatus()
          .subscribe((onlineStatus) => {
            this.onlineStatus = onlineStatus;
            this.button.disabledNext(!onlineStatus);
          });
      } else if (step === 'Step4_2') {
        this.subscribeOnlineStatus?.unsubscribe();
        this.subscribeButton.unsubscribe();

        // 3. CompactPCのIoTJobが実行済みか確認
        this.subscribeJobStatus = this.compactPC
          .observeJobStatus()
          .subscribe((jobStatus) => {
            if (jobStatus == 'SUCCEEDED') {
              this.configureImgPath = this.configureCompletedPath;
              // timeoutをクリア
              clearTimeout(this.timeoutId);
              this.processService.stepNext('Step4_3');
            }
            if (jobStatus == 'IN_PROGRESS') {
              this.configureImgPath = this.configuringPath;
              this.explanation = '設定ファイルを更新中です...';
            }
            if (jobStatus == 'QUEUED') {
              this.configureImgPath = this.configureWaitingPath;
              this.explanation = '待機状態です。';
            }
            if (jobStatus == 'FAILED') {
              this.configureImgPath = this.configureFailedPath;
              this.explanation = '設定ファイルの更新に失敗しました。';
              this.isFailure = true;
            }
          });

        // timeoutの画面を表示
        this.timeoutId = setTimeout(() => {
          this.subscribeJobStatus?.unsubscribe();
          this.configureImgPath = this.configureFailedPath;
          this.explanation = 'タイムアウトしました。';
          this.isFailure = true;
        }, 60000);
      } else if (step === 'Step4_3') {
        // 4. OKボタンを表示し、次のステップへ移行を促す
        this.explanation = '設定ファイルの更新に成功しました。';
        this.subscribeJobStatus?.unsubscribe();
        this.button.disabledNext(false);
      } else if (step === 'Step4_4') {
        // 5. CompactPCとNCが接続されている状態か確認
        this.connectImgPath = this.connectingPath;
        this.explanation = 'NCとの接続を確認中です...';
        this.subscribeConectivity = this.compactPC
          .observeNCConnectivity()
          .subscribe((conectivity) => {
            if (conectivity) {
              if (step === 'Step4_4') {
                this.processService.stepNext('Step4_5');
              }
            }
          });
      } else if (step === 'Step4_5') {
        // 6. OKボタンを表示し、次のステップへ移行を促す
        this.subscribeConectivity.unsubscribe();
        this.connectImgPath = this.connectedPath;
        this.explanation = '接続が確認できました。';
        this.button.disabledNext(false);
      }
    });
    // 2.CompactPCのConfigrationを実行！
    this.subscribeButton = this.button
      .observeClickEvent()
      .subscribe((click) => {
        console.log(click, this.step);
        if (click && this.step === 'Step4_1') {
          ////////// WEB API //////////
          const body = {
            compactPCID: compactPCID,
            machineID: machineID,
          };
          this.http.httpPostRequest<any>(`/compactpcconfigure`, body).subscribe(
            (response) => {
              console.log(response);
              this.processService.jobIDNext(response.JobID);
              this.compactPC.jobStatusNext('QUEUED');
            },
            () => {
              console.log(`WebAPI /compactpcconfigure/ Completed.`);
            }
          );
          /////////////////////////////
        }
      });
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutId);
    this.subscribeStep.unsubscribe();
    this.subscribeButton.unsubscribe();
    this.subscribeOnlineStatus.unsubscribe();
    this.subscribeJobStatus.unsubscribe();
    this.subscribeConectivity.unsubscribe();
  }

  previousPage(nowPage: string): void {
    const index = this.NCState.indexOf(nowPage);
    if (index > 0) {
      this.nowPage = this.NCState[index - 1];
    }
  }

  nextPage(nowPage: string): void {
    const index = this.NCState.indexOf(nowPage);
    if (index < 2) {
      this.nowPage = this.NCState[index + 1];
    }
  }

  stylePrePageing(nowPage: string): string {
    if (nowPage === this.NCState[0]) {
      return 'hidden';
    }
    return '';
  }

  styleNextPageing(nowPage: string): string {
    if (nowPage === this.NCState[2]) {
      return 'hidden';
    }
    return '';
  }

  retryConfigure(): void {
    this.isFailure = false;
    this.processService.stepNext('Step4_1');
  }
}
