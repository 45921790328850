<app-header [nowStep]="stepSub(step)" [compactPCID]="compactPCID" [machineID]="machineID"
  [compactPCStatus]="compactPCStatus" [operationPhase]="operationPhase" [testProcess]="testProcess">
</app-header>

<body>
  <app-comment [disabled]="commentDisabled(step)" [step]="step" [comment]="comment" [machineID]="machineID">
  </app-comment>

  <router-outlet></router-outlet>
</body>

<app-under-button [nowStep]="step" [display]="stepToDisplayButton(step)" (NextStep)="receiveStep($event)">
</app-under-button>