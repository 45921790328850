// ---------------------------------------------------------------------
// <copyright file="step.enum.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

/**
 * Stepの種類と、付随するデータの保存場所
 */
export enum Steps {
  Step0,
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
}

export enum DividedSteps {
  Step0_1, // Step0
  Step1_1, // Step1
  Step2_1, // Step2
  Step2_2,
  Step2_3,
  Step3_1, // Step3
  Step3_2,
  Step3_3,
  Step4_1, // Step4
  Step4_2,
  Step4_3,
  Step4_4,
  Step4_5,
  Step5_1, // Step5
  Step5_2,
  Step5_3,
  Step5_4,
  Home,
}

export enum Comment {
  Step0_1 = 'これから出荷前検査を行います。出荷前検査ワゴンを用意しましょう。', // Step0
  Step1_1 = '下の画面に従い、NCの設定画面からネットワーク設定を行いましょう。', // Step1
  Step2_1 = 'コンパクトPCのQRコードを読み取ってください。',
  Step2_2 = '検査工程のQRコードを読み取ってください。', // Step2
  Step2_3 = '検査看板の機番QRコードを読み取ってください。',
  Step3_1 = 'の詳細スペックを設定してください。', // Step3
  Step3_2 = 'で取得しない軸がある場合、無効にする軸を選択してください。',
  Step3_3 = '機械情報をクラウドに保存します。',
  Step4_1 = 'LANケーブルとNCを下記の手順に沿って接続してください。', // Step4
  Step4_2 = 'コンパクトPCの設定を書き換えます。',
  Step4_3 = 'コンパクトPCの設定を書き換えました。',
  Step4_4 = 'コンパクトPCとNCの接続を確認します。',
  Step4_5 = 'コンパクトPCとNCの接続を確認できました。',
  Step5_1 = '切削速度100%、送り速度100%(一度割り出しは10%)で診断プログラム実行開始し、OKを押してください。(干渉確認で実行する場合も、下のOKを押して工程を進めてください)', // Step5
  Step5_2 = '',
  Step5_3 = '',
  Step5_4 = '',
}

export enum DisplayStatus {
  None,
  One,
  Two,
}
