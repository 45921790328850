// ---------------------------------------------------------------------
// <copyright file="lan-nc-connecting-photo.component.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import 'keen-slider/keen-slider.min.css';

import KeenSlider, { KeenSliderInstance } from 'keen-slider';

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-lan-nc-connecting-photo',
  templateUrl: './lan-nc-connecting-photo.component.html',
  styleUrls: ['./lan-nc-connecting-photo.component.css'],
})
export class LanNcConnectingPhotoComponent implements OnInit {
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }
  @ViewChild('sliderRef') sliderRef: ElementRef<HTMLElement>;
  images: String[] = [
    'https://images.unsplash.com/photo-1590004953392-5aba2e72269a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=500&w=800&q=80',
    'https://images.unsplash.com/photo-1590004845575-cc18b13d1d0a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=500&w=800&q=80',
    'https://images.unsplash.com/photo-1590004987778-bece5c9adab6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=500&w=800&q=80',
    'https://images.unsplash.com/photo-1590005176489-db2e714711fc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=500&w=800&q=80',
  ];
  loaded: boolean[] = [true];
  slider: KeenSliderInstance = null;

  ngAfterViewInit() {
    setTimeout(() => {
      this.slider = new KeenSlider(this.sliderRef.nativeElement, {
        animationEnded: (s) => {
          const idx = s.track.details.rel;
          this.loaded[idx] = true;
        },
        loop: true,
        initial: 0,
      });
    });
  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy();
  }
}
