<div class="type">
  {{spindle.type}}
</div>
<div class="input-box">
  <button id="spindle" *ngFor="let option of spindle.values"
    [class]="selectedSpecCSS(machineSpec.SpindleType, option.value)"
    (click)="selectSpec(spindle, option.value)">{{option.text}}</button>
</div>
<div class="type">
  {{speed.type}}
</div>
<div class="input-box">
  <input type="number" [(ngModel)]="machineSpec.SpindleSpeed" (change)="inputSpeed($event)">
  <span class="unit">,000min<sup>-1</sup></span>
</div>
<div class="type">
  {{assetB.type}}
</div>
<div class="input-box">
  <button *ngFor="let option of assetB.values" [class]="selectedSpecCSS(machineSpec.BSpindleTable, option.value)"
    (click)="selectSpec(assetB, option.value)">{{option.text}}</button>
</div>
<div class="type">
  {{jig.type}}
</div>
<div class="input-box">
  <button *ngFor="let option of jig.values" [class]="selectedSpecCSS(machineSpec.JigExist, option.value)"
    (click)="selectSpec(jig, option.value)">{{option.text}}</button>
</div>