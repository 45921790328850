// ---------------------------------------------------------------------
// <copyright file="button.service.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { BehaviorSubject, Observable } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ButtonService {
  public clickEvent: BehaviorSubject<boolean>;
  public disabled: BehaviorSubject<boolean>;

  constructor() {
    this.clickEvent = new BehaviorSubject(false);
    this.disabled = new BehaviorSubject(false);
  }

  clickEventNext(click: boolean): void {
    this.clickEvent.next(click);
  }

  disabledNext(disabled: boolean): void {
    this.disabled.next(disabled);
  }

  observeClickEvent(): Observable<boolean> {
    return this.clickEvent;
  }

  observeDisabled(): Observable<boolean> {
    return this.disabled;
  }
}
