// ---------------------------------------------------------------------
// <copyright file="result-button.component.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

/**
 * 機械詳細画面から画面遷移するためのボタンを表示するコンポーネント
 */
@Component({
  selector: 'app-result-button',
  templateUrl: './result-button.component.html',
  styleUrls: ['./result-button.component.css'],
})
export class ResultButtonComponent implements OnInit {
  @Input() mode: string;
  @Output() event: EventEmitter<string> = new EventEmitter();
  public buttonText: { list: string; info: string } = {
    list: 'トップへ戻る',
    info: '機械一覧へ戻る',
  };

  constructor() {}

  ngOnInit(): void {}

  clickMoveURL(mode: string): void {
    this.event.emit(mode);
  }
}
