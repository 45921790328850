// ---------------------------------------------------------------------
// <copyright file="configure-json.component.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { ButtonService } from 'src/app/services/button.service';
import { ProcessService } from 'src/app/services/process.service';

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configure-json',
  templateUrl: './configure-json.component.html',
  styleUrls: ['./configure-json.component.css'],
})
export class ConfigureJsonComponent implements OnInit {
  public explanation: string;
  constructor(
    private processService: ProcessService,
    private buttonService: ButtonService
  ) {}

  ngOnInit(): void {
    this.explanation = 'クラウドに機械情報を保存中です...';
    this.processService
      .observeIsCompleteJsonPut()
      .subscribe((isCompleteJsonPut) => {
        console.log('isCompleteJsonPut');
        console.log(isCompleteJsonPut);
        if (isCompleteJsonPut) {
          this.buttonService.disabledNext(false);
          this.explanation = 'クラウドに機械情報を保存しました。';
        }
      });
  }
}
