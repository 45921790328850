<div class="upper">
  <div class="left">
    <span class="logout" (click)="clickLogout()" routerLink="/login">ログアウト</span>
  </div>
  <div class="center">
    <div class="comment">{{name | username}}さん、こんにちワン！</div>
  </div>
</div>
<img [src]="dogPath" class="image">
<app-app-info></app-app-info>

<div class="center">
  <button routerLink="/step0">検査実施</button>
</div>
<div class="center">
  <button routerLink="/list">診断結果一覧</button>
</div>