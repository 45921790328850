// ---------------------------------------------------------------------
// <copyright file="qrcode.component.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';

/**
 * QRコードの画面を出力、データを読み取るコンポーネント
 */
@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.css'],
})
export class QrcodeComponent implements OnInit {
  @Output() qrcodeData: EventEmitter<string> = new EventEmitter();
  public readonly allowedFormats = [
    BarcodeFormat.QR_CODE,
    BarcodeFormat.EAN_13,
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    /*, ...*/
  ];

  public cameras: MediaDeviceInfo[];
  public currentCamera: MediaDeviceInfo;
  public currentCameraIndex: number;

  @ViewChild('scanner', { static: false })
  scanner: ZXingScannerComponent;

  constructor() {}

  ngOnInit(): void {}

  // デバイスについているカメラのリストを取得する
  onCamerasFound(event: MediaDeviceInfo[]): void{
    this.cameras = event;

    if (this.cameras.length > 0) {
      this.currentCameraIndex = 0
      this.currentCamera = this.cameras[this.currentCameraIndex]
    }
  }

  // カメラ切り替え
  onCameraChange(): void {
    this.currentCameraIndex = (this.currentCameraIndex + 1) % this.cameras.length
    this.currentCamera = this.cameras[this.currentCameraIndex]
  }

  // QRコードを読み込めたとき
  scanSuccessHandler(event: string): void {
    console.log(event);
    this.qrcodeData.emit(event);
  }

  // QRコードの読み込み中にエラーが起こったとき
  scanErrorHandler(error: any): void {
    console.log(error);
  }

  // QRコードをデコードできなかったとき
  scanFailureHandler(): void {
    console.log('scanFailure');
  }

  // QRコードを読み込めた後に問題がないとき
  scanCompleteHandler(error: any): void {
    console.log(error);
  }
}
