<ng-container [ngSwitch]="step">

    <body *ngSwitchCase="Step2_box.first">
        <div class="label">コンパクトPC</div>
        <app-qrcode class="qrcode" (qrcodeData)="compactPCQrcodeAccept($event)"></app-qrcode>
        <div class="text-position">
            <div class="textbox">{{compactPC | toKomainu}}</div>
        </div>
    </body>

    <body *ngSwitchCase="Step2_box.second">
        <div class="label">検査工程</div>
        <app-qrcode class=" qrcode" (qrcodeData)="testProcessQrcodeAccept($event)"></app-qrcode>
        <div class="text-position">
            <div class="textbox">{{testProcess | qrTestprocess}}</div>
        </div>
    </body>

    <body *ngSwitchCase="Step2_box.third">
        <div class="label">機番</div>
        <app-qrcode class="qrcode" (qrcodeData)="machineIDQrcodeAccept($event)"></app-qrcode>
        <div class="text-position">
            <div class="textbox">{{machineID}}</div>
        </div>
    </body>

</ng-container>