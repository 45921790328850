// ---------------------------------------------------------------------
// <copyright file="machinelist.ts" company="WALC Inc."
// @author Naoya Abe
// (C) 2022 WALC Inc. All rights reserved.
// </copyright>
// ---------------------------------------------------------------------

import { AssetStatus } from 'src/app/models/asset-status';

import { AssetScore } from './asset-score';

export class MachineList {
  MachineList: Machine[];
  sortedMachineList: Machine[];

  constructor(machines: Machine[]) {
    this.MachineList = [];
    for (const machine of machines) {
      this.MachineList.push(
        new Machine(
          machine.MachineID,
          machine.TestProcessList,
          machine.CommentNumber,
          machine.LatestLastUpdate
        )
      );
    }
  }

  //日付の降順に並び替え
  sortDateDown(): Machine[] {
    let _machineList = this.MachineList;
    this.sortedMachineList = _machineList.sort((n1, n2) => {
      let lastUpdateMachineN1 = n1.LatestLastUpdate;
      let lastUpdateMachineN2 = n2.LatestLastUpdate;
      if (lastUpdateMachineN1 > lastUpdateMachineN2) {
        return -1;
      }
      if (lastUpdateMachineN1 < lastUpdateMachineN2) {
        return 1;
      }
      return 0;
    });
    return this.sortedMachineList;
  }
}

export class TestProcessHolder {
  Operator: string;
  Approver: string;
  Approval: boolean;
  LastUpdate: string;
  Assets: AssetScore;

  constructor(
    operator: string,
    approver: string,
    approval: boolean,
    LastUpdate: string,
    assets: AssetScore
  ) {
    this.Operator = operator;
    this.Approver = approver;
    this.Approval = approval;
    this.LastUpdate = LastUpdate;
    this.Assets = new AssetScore(assets);
  }
}

interface TestProcesses {
  [testProcess: string]: TestProcessHolder;
}

export class Machine {
  MachineID: string;
  TestProcessList: TestProcesses;
  CommentNumber: number;
  LatestLastUpdate: string;

  constructor(
    machineID: string,
    testProcessList: TestProcesses,
    commentNumber: number,
    LatestLastUpdate: string
  ) {
    this.MachineID = machineID;
    this.TestProcessList = testProcessList;
    this.CommentNumber = commentNumber;
    this.LatestLastUpdate = LatestLastUpdate;
  }
}

export const AllTestProcess = [
  'SPP',
  'FIN',
  'MMD',
  'FUT',
  'CLT',
  'OPT',
  'CUT',
  'LBM',
  'RUN',
  'HTM',
  'COV',
  'ACT',
  'FAA',
];

export const TitleNames = [
  '機番',
  ' ',
  '工程',
  '検査日',
  '判定',
  '担当',
  '承認',
  'コメント',
];
